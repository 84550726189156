import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../../context/AlertContext";
import { ConfiguracionContext } from "../../../context/ConfiguracionContext";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Link, Redirect } from "react-router-dom";
import Spinner from "../Spinner";
import { AuthContext } from "../../../context/AuthContext";
import { ServiciosContext } from "../../../context/ServiciosContext";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";
import { ScrollPanel } from "primereact/scrollpanel";
import "../../../assets/css/ScrollPanelDemo.css";
import "../../../assets/css/DataTableDemo.css";
import "../../../assets/css/DialogDemo.css";
import ConsultaServicio from "../servicios/ConsultaServicio";
import NotasServicio from "../servicios/NotasServicio";
import { RadioButton } from "primereact/radiobutton";
import { alignment, defaultDataType } from "export-xlsx";
import ExcelExport from "export-xlsx";
import { jsPDF } from "jspdf";
import { ProgressBar } from "primereact/progressbar";
import hcOffcanvasNav from "hc-offcanvas-nav";
import { saveAs } from "file-saver";
import { Navigate, useNavigate } from "react-router-dom";
import Barra from "../Barra";

const agrupar = [
  {
    id: 1,
    nombre: "Base",
  },
  {
    id: 2,
    nombre: "ColorInicial",
  },
  {
    id: 3,
    nombre: "ColorFinal",
  },
  {
    id: 4,
    nombre: "Antecedente",
  },
  {
    id: 5,
    nombre: "Tratamiento",
  },
  {
    id: 6,
    nombre: "MotivoConsulta",
  },
  {
    id: 7,
    nombre: "Diagnostico",
  },
  {
    id: 8,
    nombre: "IDTitular",
  },
  {
    id: 9,
    nombre: "Documento",
  },
  {
    id: 10,
    nombre: "FechaTomado",
  },
  {
    id: 11,
    nombre: "FechaPasado",
  },
  {
    id: 12,
    nombre: "FechaSalida",
  },
  {
    id: 13,
    nombre: "FechaLlegada",
  },
  {
    id: 14,
    nombre: "FechaFin",
  },
  {
    id: 15,
    nombre: "Móvil",
  },
  {
    id: 16,
    nombre: "Grupo",
  },
];

export default function ListadosServicios(props) {
  //const classes = useStyles();
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  let navigate = useNavigate();

  const exportColumns = [
    { title: "ID", dataKey: "id" },
    { title: "Indicativo", dataKey: "Indicativo" },
  ];

  const [visibleFechas, setVisibleFechas] = useState(true);
  const [fechas, setFechas] = useState(null);
  const [visible, setVisible] = useState(false);
  const [visibleUpload, setVisibleUpload] = useState(false);
  const toast = useRef(null);
  const [file, setFile] = useState(null);
  const [filename, setFilename] = useState(null);
  const [servicio, setServicio] = useState(null);
  const [grupo, setGrupo] = useState(null);
  const [val, setVal] = useState(null);
  const [agrupado, setAgrupado] = useState(null);
  const [radio, setRadio] = useState("tomado");
  const [movilesDerivados, setMovilesDerivados] = useState(null);
  const [color, setColor] = useState(null);
  const [base, setBase] = useState(null);
  const [chofer, setChofer] = useState(null);
  const [medico, setMedico] = useState(null);
  const [enfermero, setEnfermero] = useState(null);
  const [empresa, setEmpresa] = useState(null);
  const [coseguro, setCoseguro] = useState(null);
  const [pediatrico, setPediatrico] = useState(null);
  const [group, setGroup] = useState(null);
  const [movilPropio, setMovilPropio] = useState(null);
  const [movilPropioCheck, setMovilPropioCheck] = useState(false);
  const [movilDerivado, setMovilDerivado] = useState(null);
  const [movilDerivadoCheck, setMovilDerivadoCheck] = useState(false);
  const [titularId, setTitularId] = useState(null);
  const [titularNombre, setTitularNombre] = useState(null);
  const [titularSeleccionado, setTitularSeleccionado] = useState(null);
  const [dni, setDni] = useState(null);
  const [domicilio, setDomicilio] = useState(null);

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso,
    statusAcceso2,
    colores,
    bases,
    grupos,
    empresas,
    moviles,
    medicos,
    choferes,
    enfermeros,
    configuracion,
    obtenerAcceso,
    obtenerAcceso2,
    obtenerColores,
    obtenerMoviles,
    obtenerBases,
    obtenerChoferes,
    obtenerMedicos,
    obtenerEnfermeros,
    obtenerGrupos,
    obtenerEmpresas,
  } = configuracionContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    serviciosFinalizados,
    rows,
    filtrosListadosServicios,
    serviciosFinalizadosExcel,
    obtenerServiciosFinalizados,
    obtenerDatosTitulares,
    setRows,
  } = serviciosContext;

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({ usuarioId: state.usuario.id, layout: "Listados" });
      obtenerColores();
      obtenerMoviles({ todos: false, propios: false });
      obtenerBases({ todos: false });
      obtenerChoferes({ todos: false });
      obtenerMedicos({ todos: false });
      obtenerEnfermeros({ todos: false });
      obtenerGrupos();
      obtenerEmpresas();
    }
  }, [state.id]);

  setTimeout(() => {
    if (statusAcceso === 400) {
      accesoDenegado();
    }
  }, 3000);

  if (!movilesDerivados && moviles && moviles.length > 0) {
    setMovilesDerivados(moviles);
    setTimeout(() => {
      obtenerMoviles({ todos: false, propios: true });
    }, 2000);
  }

  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];
    return day + "/" + month + "/" + year;
  }

  function formatDateTime(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var thisTime = allDate[1].split(":");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    var newTime = [thisTime[0], thisTime[1]].join(":");
    return newDate + " " + newTime;
  }

  const groupBy = (prop) => (data) => {
    return data.reduce((dict, item) => {
      const { [prop]: _, ...rest } = item;
      dict[item[prop]] = [...(dict[item[prop]] || []), rest];
      return dict;
    }, {});
  };

  const calendar = (e) => {
    setVisibleFechas(true);
  };

  const exitClick = (e) => {
    onHideFechas();
    navigate(`/`);
    var Nav = new hcOffcanvasNav("#main-nav", {
      disableAt: false,
      customToggle: ".toggle",
      levelSpacing: 40,
      navTitle: "Home",
      levelTitles: true,
      levelTitleAsBack: true,
      pushContent: "#container",
      labelClose: false,
      position: "left",
      levelOpen: "overlap",
    });
    Nav.open(1, 2);
  };

  const startClick = (e) => {
    setTimeout(() => {
      navigate(`/listadosservicios`);
      setVisibleFechas(true);
    }, 2000);
  };

  const onHideFechas = (e) => {
    setVisibleFechas(false);
  };

  const aceptarFechas = async (exportType) => {
    //e.preventDefault();
    //Validar

    if (
      !fechas ||
      fechas.desde === undefined ||
      fechas.desde === null ||
      fechas.desde.trim() === ""
    ) {
      mensajeAlerta(
        "Listado de Servicios",
        "Fecha desde es necesaria !.",
        "error"
      );
      return;
    }

    if (
      !fechas ||
      fechas.hasta === undefined ||
      fechas.hasta === null ||
      fechas.hasta.trim() === ""
    ) {
      mensajeAlerta(
        "Listado de Servicios",
        "Fecha hasta es necesaria !.",
        "error"
      );
      return;
    }

    if (
      grupo !== null &&
      grupo === 15 &&
      !movilPropioCheck &&
      !movilDerivadoCheck
    ) {
      mensajeAlerta(
        "Listado de Servicios",
        "Para agrupar por móvil es necesario indicar si son propios o derivados !.",
        "error"
      );
      return;
    }

    let fdesde = fechas.desde; //+ "T00:00:00";
    let fhasta = fechas.hasta; //+ "T23:59:59";

    var elem;

    await obtenerServiciosFinalizados({
      fdesde: fdesde,
      fhasta: fhasta,
      color,
      group,
      base,
      movilPropioCheck,
      movilPropio,
      movilDerivadoCheck,
      movilDerivado,
      radio,
      titularSeleccionado,
      coseguro,
      pediatrico,
      chofer,
      medico,
      enfermero,
      edadPediatrico: configuracion.edadPediatrico,
      destinatario: configuracion.destinatario,
      dni,
      domicilio,
      empresa,
    }).then((data) => {
      if (exportType === "excel") {
        elem = document.getElementById("expButtonExcel");
        elem.click();
      }
      if (exportType === "pdf") {
        elem = document.getElementById("expButtonPdf");
        elem.click();
      }
    });
    onHideFechas();
    setColor(null);
    setChofer(null);
    setMedico(null);
    setEnfermero(null);
    setEmpresa(null);
    setBase(null);
    setGroup(null);
    setDni(null);
    setDomicilio(null);
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const exportExcel = () => {
    if (serviciosFinalizadosExcel && serviciosFinalizadosExcel.length > 0) {
      if (grupo !== null && grupo !== "") {
        //var result = groupBy(serviciosFinalizadosExcel, 'Antecedente');

        let campo = agrupar[grupo - 1].nombre;

        if (campo === "Móvil" && movilPropioCheck) {
          campo = "Movil";
        }
        if (campo === "Móvil" && movilDerivadoCheck) {
          campo = "Derivado";
        }
        if (campo === "Móvil" && !movilPropioCheck && !movilDerivadoCheck) {
          campo = "Movil";
        }

        const result = Object.entries(
          groupBy(campo)(serviciosFinalizadosExcel)
        ).map(([key, value]) => ({ nombre: key, datos: value }));

        //console.log(result);
        //console.log(dataEX);

        let arrayEX = [];
        let arrayItem = [];
        let tCosAbonar = 0;
        let tCosAbonado = 0;
        let tCantidad = 0;
        let tValorServicio = 0;
        let tCuota = 0;
        for (var i = 0; i < result.length; i++) {
          let cosAbonar = 0;
          let cosAbonado = 0;
          let cantidad = 0;
          let valorServicio = 0;
          let cuota = 0;
          let promedio = 0;
          let nombreTitular = "";
          let nombreAfiliado = "";
          for (var j = 0; j < result[i].datos.length; j++) {
            cantidad = cantidad + 1;
            cosAbonar = cosAbonar + Number(result[i].datos[j].CoseguroAbonar);
            cosAbonado =
              cosAbonado + Number(result[i].datos[j].CoseguroAbonado);
            valorServicio =
              valorServicio + Number(result[i].datos[j].ValorServicio);
            cuota = Number(result[i].datos[j].Cuota);
            promedio = Number(cuota / cantidad);
            tCantidad = tCantidad + 1;
            tCosAbonar = tCosAbonar + Number(result[i].datos[j].CoseguroAbonar);
            tCosAbonado =
              tCosAbonado + Number(result[i].datos[j].CoseguroAbonado);
            tValorServicio =
              tValorServicio + Number(result[i].datos[j].ValorServicio);
            nombreTitular = result[i].datos[j].NombreTitular;
            nombreAfiliado = result[i].datos[j].NombreAfiliado;
            tCuota = tCuota + Number(result[i].datos[j].Cuota);
          }
          arrayItem.push({
            nombre: result[i].nombre,
            nombreTitular,
            nombreAfiliado,
            cantidad,
            cosAbonar,
            cosAbonado,
            valorServicio,
            cuota,
            promedio,
          });
        }
        arrayItem.push({
          nombre: "Total",
          cantidad: tCantidad,
          cosAbonar: tCosAbonar,
          cosAbonado: tCosAbonado,
          valorServicio: tValorServicio,
          cuota: tCuota,
          promedio: tCuota / tCantidad,
        });

        arrayEX.push({
          data: arrayItem,
        });

        let SETTINGS_FOR_EXPORT = {
          // Table settings
          fileName: "serviciosFinalizados",
          workSheets: [
            {
              sheetName: "serviciosFinalizados",
              startingRowNumber: 1,
              gapBetweenTwoTables: 2,
              tableSettings: {
                data: {
                  importable: true,
                  tableTitle:
                    "Servicios Finalizados desde el " +
                    formatDateTime(fechas.desde) +
                    " Hasta el " +
                    formatDateTime(fechas.hasta),
                  headerDefinition:
                    grupo <= 7 || grupo > 9
                      ? [
                          {
                            name: agrupar[grupo - 1].nombre,
                            key: "nombre",
                            width: 25,
                            style: { alignment: alignment.middleLeft },
                          },
                          {
                            name: "Cantidad",
                            key: "cantidad",
                            width: 18,
                            editable: true,
                            style: { alignment: alignment.middleLeft },
                          },
                          {
                            name: "Coseguro Abonar",
                            key: "cosAbonar",
                            width: 18,
                            dataType: defaultDataType.number,
                            editable: true,
                            style: { alignment: alignment.middleRight },
                          },
                          {
                            name: "Coseguro Abonado",
                            key: "cosAbonado",
                            width: 18,
                            dataType: defaultDataType.number,
                            style: { alignment: alignment.middleRight },
                          },
                          {
                            name: "Valor Servicio",
                            key: "valorServicio",
                            width: 18,
                            dataType: defaultDataType.number,
                            style: { alignment: alignment.middleRight },
                          },
                        ]
                      : grupo === 8
                      ? [
                          {
                            name: agrupar[grupo - 1].nombre,
                            key: "nombre",
                            width: 25,
                            style: { alignment: alignment.middleLeft },
                          },
                          {
                            name: "Nombre Titular",
                            key: "nombreTitular",
                            width: 40,
                            style: { alignment: alignment.middleLeft },
                          },
                          {
                            name: "Cantidad",
                            key: "cantidad",
                            width: 18,
                            editable: true,
                            style: { alignment: alignment.middleLeft },
                          },
                          {
                            name: "Coseguro Abonar",
                            key: "cosAbonar",
                            width: 18,
                            dataType: defaultDataType.number,
                            editable: true,
                            style: { alignment: alignment.middleRight },
                          },
                          {
                            name: "Coseguro Abonado",
                            key: "cosAbonado",
                            width: 18,
                            dataType: defaultDataType.number,
                            style: { alignment: alignment.middleRight },
                          },
                          {
                            name: "Valor Servicio",
                            key: "valorServicio",
                            width: 18,
                            dataType: defaultDataType.number,
                            style: { alignment: alignment.middleRight },
                          },
                          {
                            name: "Valor Cuota",
                            key: "cuota",
                            width: 18,
                            dataType: defaultDataType.number,
                            style: { alignment: alignment.middleRight },
                          },
                          {
                            name: "Promedio",
                            key: "promedio",
                            width: 18,
                            dataType: defaultDataType.number,
                            style: { alignment: alignment.middleRight },
                          },
                        ]
                      : grupo === 9
                      ? [
                          {
                            name: agrupar[grupo - 1].nombre,
                            key: "nombre",
                            width: 25,
                            style: { alignment: alignment.middleLeft },
                          },
                          {
                            name: "Nombre Afiliado",
                            key: "nombreAfiliado",
                            width: 40,
                            style: { alignment: alignment.middleLeft },
                          },
                          {
                            name: "Cantida",
                            key: "cantidad",
                            width: 18,
                            editable: true,
                            style: { alignment: alignment.middleLeft },
                          },
                          {
                            name: "Coseguro Abonar",
                            key: "cosAbonar",
                            width: 18,
                            dataType: defaultDataType.number,
                            editable: true,
                            style: { alignment: alignment.middleRight },
                          },
                          {
                            name: "Coseguro Abonado",
                            key: "cosAbonado",
                            width: 18,
                            dataType: defaultDataType.number,
                            style: { alignment: alignment.middleRight },
                          },
                          {
                            name: "Valor Servicio",
                            key: "valorServicio",
                            width: 18,
                            dataType: defaultDataType.number,
                            style: { alignment: alignment.middleRight },
                          },
                        ]
                      : null,
                },
              },
            },
          ],
        };

        const excelExport = new ExcelExport();
        excelExport.downloadExcel(SETTINGS_FOR_EXPORT, arrayEX);
      } else {
        import("xlsx").then((xlsx) => {
          const worksheet = xlsx.utils.json_to_sheet(serviciosFinalizadosExcel);
          const workbook = {
            Sheets: { data: worksheet },
            SheetNames: ["data"],
          };
          const excelBuffer = xlsx.write(workbook, {
            bookType: "xlsx",
            type: "array",
          });
          saveAsExcelFile(excelBuffer, "serviciosFinalizados");
        });
      }
    }

    startClick();
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      saveAs(
        data,
        fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
      );
    });
  };

  const exportPdf = () => {
    if (serviciosFinalizadosExcel && serviciosFinalizadosExcel.length > 0) {
      let PDF_EXTENSION = ".pdf";
      let fecha = new Date();

      if (grupo !== null && grupo !== "") {
        //var result = groupBy(serviciosFinalizadosExcel, 'Antecedente');

        let campo = agrupar[grupo - 1].nombre;

        if (campo === "Móvil" && movilPropioCheck) {
          campo = "Movil";
        }
        if (campo === "Móvil" && movilDerivadoCheck) {
          campo = "Derivado";
        }
        if (campo === "Móvil" && !movilPropioCheck && !movilDerivadoCheck) {
          campo = "Movil";
        }

        const result = Object.entries(
          groupBy(campo)(serviciosFinalizadosExcel)
        ).map(([key, value]) => ({ nombre: key, datos: value }));

        //console.log(result);
        //console.log(dataEX);

        let arrayEX = [];
        let arrayItem = [];
        let tCosAbonar = 0;
        let tCosAbonado = 0;
        let tCantidad = 0;
        let tValorServicio = 0;
        let tCuota = 0;
        for (var i = 0; i < result.length; i++) {
          let cosAbonar = 0;
          let cosAbonado = 0;
          let cantidad = 0;
          let valorServicio = 0;
          let cuota = 0;
          let promedio = 0;
          let nombreTitular = "";
          let nombreAfiliado = "";
          for (var j = 0; j < result[i].datos.length; j++) {
            cantidad = cantidad + 1;
            cosAbonar = cosAbonar + Number(result[i].datos[j].CoseguroAbonar);
            cosAbonado =
              cosAbonado + Number(result[i].datos[j].CoseguroAbonado);
            valorServicio =
              valorServicio + Number(result[i].datos[j].ValorServicio);
            cuota = Number(result[i].datos[j].Cuota);
            promedio = Number(cuota / cantidad);
            tCantidad = tCantidad + 1;
            tCosAbonar = tCosAbonar + Number(result[i].datos[j].CoseguroAbonar);
            tCosAbonado =
              tCosAbonado + Number(result[i].datos[j].CoseguroAbonado);
            tValorServicio =
              tValorServicio + Number(result[i].datos[j].ValorServicio);
            nombreTitular = result[i].datos[j].NombreTitular;
            nombreAfiliado = result[i].datos[j].NombreAfiliado;
            tCuota = tCuota + Number(result[i].datos[j].Cuota);
          }
          arrayItem.push({
            nombre: result[i].nombre,
            nombreTitular,
            nombreAfiliado,
            cantidad,
            cosAbonar,
            cosAbonado,
            valorServicio,
            cuota,
            promedio,
          });
        }
        arrayItem.push({
          nombre: "Total",
          cantidad: tCantidad,
          cosAbonar: tCosAbonar,
          cosAbonado: tCosAbonado,
          valorServicio: tValorServicio,
          cuota: tCuota,
          promedio: tCuota / tCantidad,
        });

        arrayEX.push({
          data: arrayItem,
        });

        const doc = new jsPDF();

        doc.setFontSize(9);
        doc.text(
          "Servicios Finalizados desde el " +
            formatDateTime(fechas.desde) +
            " Hasta el " +
            formatDateTime(fechas.hasta),
          10,
          10
        );

        doc.text(
          fecha.getDate() +
            "/" +
            (fecha.getMonth() + 1) +
            "/" +
            fecha.getFullYear() +
            "  " +
            fecha.getHours() +
            ":" +
            (fecha.getMinutes() < 10
              ? "0" + fecha.getMinutes()
              : fecha.getMinutes()),
          150,
          10
        );

        doc.setFontSize(8);

        if (grupo <= 7 || grupo > 9) {
          doc.text(
            agrupar[grupo - 1].nombre +
              "                                                     Cantidad         Coseguro Abonar     Coseguro Abonado      ",
            10,
            20
          );
        }
        if (grupo === 8) {
          doc.text(
            agrupar[grupo - 1].nombre +
              "              Nombre Titular                                                           Cantidad    Cos. Abonar    Cos. Abonado    V. Servicio           V. Cuota           Promedio  ",
            10,
            20
          );
        }
        if (grupo === 9) {
          doc.text(
            agrupar[grupo - 1].nombre +
              "              Nombre Afiliado                                           Cantidad         Coseguro Abonar     Coseguro Abonado      ",
            10,
            20
          );
        }

        //pdf.text(`Hello world`, pdf.internal.pageSize.getWidth() / 2, 20, { align: 'center'} );

        let row = 30;
        for (var i = 0; i < arrayItem.length; i++) {
          if (grupo <= 7 || grupo > 9) {
            doc.text(arrayItem[i].nombre, 10, row, "left", "middle");
            doc.text(
              arrayItem[i].cantidad.toString(),
              85,
              row,
              "right",
              "middle"
            );
            doc.text(
              parseFloat(arrayItem[i].cosAbonar).toFixed(2).toString(),
              115,
              row,
              "right",
              "middle"
            );
            doc.text(
              parseFloat(arrayItem[i].cosAbonado).toFixed(2).toString(),
              145,
              row,
              "right",
              "middle"
            );
          }
          if (grupo === 8) {
            doc.text(arrayItem[i].nombre, 10, row, "left", "middle");
            if (arrayItem[i].nombreTitular !== undefined) {
              doc.text(
                arrayItem[i].nombreTitular.substring(0, 30),
                34,
                row,
                "left",
                "middle"
              );
            }
            doc.text(
              arrayItem[i].cantidad.toString(),
              105,
              row,
              "right",
              "middle"
            );
            doc.text(
              parseFloat(arrayItem[i].cosAbonar).toFixed(2).toString(),
              125,
              row,
              "right",
              "middle"
            );
            doc.text(
              parseFloat(arrayItem[i].cosAbonado).toFixed(2).toString(),
              145,
              row,
              "right",
              "middle"
            );
            doc.text(
              parseFloat(arrayItem[i].valorServicio).toFixed(2).toString(),
              165,
              row,
              "right",
              "middle"
            );
            doc.text(
              parseFloat(arrayItem[i].cuota).toFixed(2).toString(),
              185,
              row,
              "right",
              "middle"
            );
            doc.text(
              parseFloat(arrayItem[i].promedio).toFixed(2).toString(),
              205,
              row,
              "right",
              "middle"
            );
          }
          if (grupo === 9) {
            doc.text(arrayItem[i].nombre, 10, row, "left", "middle");
            if (arrayItem[i].nombreAfiliado !== undefined) {
              doc.text(
                arrayItem[i].nombreAfiliado.substring(0, 30),
                34,
                row,
                "left",
                "middle"
              );
            }
            doc.text(
              arrayItem[i].cantidad.toString(),
              105,
              row,
              "right",
              "middle"
            );
            doc.text(
              parseFloat(arrayItem[i].cosAbonar).toFixed(2).toString(),
              135,
              row,
              "right",
              "middle"
            );
            doc.text(
              parseFloat(arrayItem[i].cosAbonado).toFixed(2).toString(),
              165,
              row,
              "right",
              "middle"
            );
          }
          row = row + 5;

          if (row >= 260) {
            doc.addPage();
            doc.setFontSize(9);
            doc.text(
              "Servicios Finalizados desde el " +
                formatDate(fechas.desde) +
                " Hasta el " +
                formatDate(fechas.hasta),
              10,
              10
            );

            doc.text(
              fecha.getDate() +
                "/" +
                (fecha.getMonth() + 1) +
                "/" +
                fecha.getFullYear() +
                "  " +
                fecha.getHours() +
                ":" +
                (fecha.getMinutes() < 10
                  ? "0" + fecha.getMinutes()
                  : fecha.getMinutes()),
              150,
              10
            );

            doc.setFontSize(8);

            row = 20;
          }
        }

        doc.save(
          "serviciosFinalizados" +
            "_export_" +
            new Date().getTime() +
            PDF_EXTENSION
        );
      } else {
        const doc = new jsPDF();

        doc.setFontSize(9);
        doc.text(
          "Servicios Finalizados desde el " +
            formatDate(fechas.desde) +
            " Hasta el " +
            formatDate(fechas.hasta),
          10,
          10
        );

        doc.text(
          fecha.getDate() +
            "/" +
            (fecha.getMonth() + 1) +
            "/" +
            fecha.getFullYear() +
            "  " +
            fecha.getHours() +
            ":" +
            (fecha.getMinutes() < 10
              ? "0" + fecha.getMinutes()
              : fecha.getMinutes()),
          150,
          10
        );

        doc.setFontSize(8);

        let row = 20;

        for (var i = 0; i < serviciosFinalizadosExcel.length; i++) {
          /*
          doc.text(
            "ID: " + serviciosFinalizadosExcel[i].id,
            10,
            row,
            "left",
            "middle"
          );
          */
          doc.text(
            configuracion.destinatario !== 2
              ? "Indicativo: " + serviciosFinalizadosExcel[i].Indicativo
              : "Ind. - Id: " + serviciosFinalizadosExcel[i].IndicativoId,
            10,
            row,
            "left",
            "middle"
          );
          doc.text(
            "Tomado: " + serviciosFinalizadosExcel[i].FechaTomado,
            60,
            row,
            "left",
            "middle"
          );
          doc.text(
            "Pasado: " + serviciosFinalizadosExcel[i].FechaPasado,
            100,
            row,
            "left",
            "middle"
          );
          doc.text(
            "Salida: " + serviciosFinalizadosExcel[i].FechaSalida,
            140,
            row,
            "left",
            "middle"
          );
          row = row + 4;
          doc.text(
            "LLegada: " + serviciosFinalizadosExcel[i].FechaLlegada,
            10,
            row,
            "left",
            "middle"
          );
          doc.text(
            "Fin: " + serviciosFinalizadosExcel[i].FechaFin,
            50,
            row,
            "left",
            "middle"
          );
          doc.text(
            "Color Inicial: " + serviciosFinalizadosExcel[i].ColorInicial,
            85,
            row,
            "left",
            "middle"
          );
          doc.text(
            "Color Final: " + serviciosFinalizadosExcel[i].ColorFinal,
            120,
            row,
            "left",
            "middle"
          );
          if (serviciosFinalizadosExcel[i].Movil !== null) {
            doc.text(
              "Móvil: " + serviciosFinalizadosExcel[i].Movil,
              155,
              row,
              "left",
              "middle"
            );
          } else {
            doc.text(
              "Derivado: " + serviciosFinalizadosExcel[i].Derivado,
              150,
              row,
              "left",
              "middle"
            );
          }
          row = row + 4;
          doc.text(
            "Base: " +
              (serviciosFinalizadosExcel[i].Base !== null
                ? serviciosFinalizadosExcel[i].Base.substring(0, 22)
                : ""),
            10,
            row,
            "left",
            "middle"
          );
          doc.text(
            "Tripulación: " +
              (serviciosFinalizadosExcel[i].Medico !== null
                ? serviciosFinalizadosExcel[i].Medico
                : "") +
              (serviciosFinalizadosExcel[i].Chofer !== null
                ? "/" + serviciosFinalizadosExcel[i].Chofer
                : "") +
              (serviciosFinalizadosExcel[i].Enfermero !== null
                ? "/" + serviciosFinalizadosExcel[i].Enfermero
                : ""),
            60,
            row,
            "left",
            "middle"
          );
          row = row + 4;
          doc.text(
            "Afiliado: " +
              serviciosFinalizadosExcel[i].NombreAfiliado.substring(0, 25),
            10,
            row,
            "left",
            "middle"
          );
          doc.text(
            "Edad: " + serviciosFinalizadosExcel[i].Edad.toString(),
            65,
            row,
            "left",
            "middle"
          );
          doc.text(
            "Sexo: " + serviciosFinalizadosExcel[i].Sexo.toString(),
            80,
            row,
            "left",
            "middle"
          );
          doc.text(
            "Documento: " + serviciosFinalizadosExcel[i].Documento.toString(),
            95,
            row,
            "left",
            "middle"
          );
          doc.text(
            "Plan: " + serviciosFinalizadosExcel[i].Plan,
            130,
            row,
            "left",
            "middle"
          );
          row = row + 4;
          doc.text(
            "Domicilio: " + serviciosFinalizadosExcel[i].Domicilio,
            10,
            row,
            "left",
            "middle"
          );
          row = row + 4;
          doc.text(
            "Socio Titular: " + serviciosFinalizadosExcel[i].IDTitular,
            10,
            row,
            "left",
            "middle"
          );
          doc.text(
            "Nombre Titular: " + serviciosFinalizadosExcel[i].NombreTitular,
            50,
            row,
            "left",
            "middle"
          );
          row = row + 4;
          doc.text(
            "Antecedente: " +
              (serviciosFinalizadosExcel[i].Antecedente !== null
                ? serviciosFinalizadosExcel[i].Antecedente.substring(0, 22)
                : ""),
            10,
            row,
            "left",
            "middle"
          );
          doc.text(
            "Tratamiento: " +
              (serviciosFinalizadosExcel[i].Tratamiento !== null
                ? serviciosFinalizadosExcel[i].Tratamiento.substring(0, 22)
                : ""),
            70,
            row,
            "left",
            "middle"
          );
          doc.text(
            "Motivo Consulta: " +
              (serviciosFinalizadosExcel[i].MotivoConsulta !== null
                ? serviciosFinalizadosExcel[i].MotivoConsulta.substring(0, 30)
                : ""),
            130,
            row,
            "left",
            "middle"
          );
          row = row + 4;
          doc.text(
            "Diagnóstico: " +
              (serviciosFinalizadosExcel[i].Diagnostico !== null
                ? serviciosFinalizadosExcel[i].Diagnostico.substring(0, 30)
                : ""),
            10,
            row,
            "left",
            "middle"
          );
          doc.text(
            "Cos. Abonar: " +
              parseFloat(serviciosFinalizadosExcel[i].CoseguroAbonar)
                .toFixed(2)
                .toString(),
            100,
            row,
            "left",
            "middle"
          );

          doc.text(
            "Cos. Abonado: " +
              parseFloat(serviciosFinalizadosExcel[i].CoseguroAbonado)
                .toFixed(2)
                .toString(),
            140,
            row,
            "left",
            "middle"
          );

          doc.text(
            "Covid: " +
              (serviciosFinalizadosExcel[i].covid !== null
                ? serviciosFinalizadosExcel[i].covid
                : ""),
            180,
            row,
            "left",
            "middle"
          );

          row = row + 4;
          doc.text(
            "Detalle Diagnóstico: " +
              (serviciosFinalizadosExcel[i].DetalleDiagnostico !== null
                ? serviciosFinalizadosExcel[i].DetalleDiagnostico
                : ""),
            10,
            row,
            "left",
            "middle"
          );
          row = row + 4;

          doc.line(10, row, 200, row);
          row = row + 4;

          if (row >= 260) {
            doc.addPage();
            doc.setFontSize(9);
            doc.text(
              "Servicios Finalizados desde el " +
                formatDate(fechas.desde) +
                " Hasta el " +
                formatDate(fechas.hasta),
              10,
              10
            );

            doc.text(
              fecha.getDate() +
                "/" +
                (fecha.getMonth() + 1) +
                "/" +
                fecha.getFullYear() +
                "  " +
                fecha.getHours() +
                ":" +
                (fecha.getMinutes() < 10
                  ? "0" + fecha.getMinutes()
                  : fecha.getMinutes()),
              150,
              10
            );

            doc.setFontSize(8);

            row = 20;
          }
        }

        row = row + 4;
        doc.text(
          "Cantidad de Servicios " + serviciosFinalizadosExcel.length,
          100,
          row
        );

        /*
        doc.save(
          "serviciosFinalizados" +
            "_export_" +
            new Date().getTime() +
            PDF_EXTENSION
        );
        */
        window.open(doc.output("bloburl"));
      }
    }

    startClick();
  };

  const renderHeaderFechas = () => {
    return (
      <div>
        <Tag value="Listado de Servicios"></Tag>
      </div>
    );
  };

  const renderFooterFechas = () => {
    return (
      <div>
        <button
          id="expButtonExcel"
          type="button"
          onClick={(e) => {
            exportExcel();
          }}
          style={{ visibility: "hidden" }}
        >
          Export Excel
        </button>
        <button
          id="expButtonPdf"
          type="button"
          onClick={(e) => {
            exportPdf();
          }}
          style={{ visibility: "hidden" }}
        >
          Export Pdf
        </button>
        <Button
          icon="pi pi-file-excel"
          onClick={(e) => {
            aceptarFechas("excel");
          }}
          className="p-button-raised p-button-success"
          data-pr-tooltip="XLS"
          label="Excel"
        />
        <Button
          icon="pi pi-file-pdf"
          onClick={(e) => {
            aceptarFechas("pdf");
            //exportPdf();
          }}
          className="p-button-raised p-button-warning"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
          label="PDF"
        />
        {/*
        <ButtonMui
          onClick={aceptarFechas}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        */}
        <ButtonMui
          onClick={exitClick}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const buscarAfiliados = () => {
    if (titularNombre !== null && titularNombre !== "") {
      obtenerDatosTitulares({
        opcion: "Apellido y nombre",
        texto: titularNombre,
      });
    }
    if (titularId !== null && titularId !== "") {
      obtenerDatosTitulares({
        opcion: "Número de socio",
        texto: titularId,
      });
    }
  };

  //console.log(moviles);
  //console.log(rows);

  return (
    <Fragment>
      <Barra />
      <Toast ref={toast} />
      {statusAcceso === 0 || statusAcceso === 400 ? <Spinner /> : null}
      {statusAcceso === 200 ? (
        <Fragment>
          {bases && colores && grupos && choferes ? (
            <Dialog
              header={renderHeaderFechas()}
              visible={visibleFechas}
              closable={false}
              footer={renderFooterFechas()}
              breakpoints={{ "960px": "75vw" }}
              style={{ width: "90vw", paddingBottom: "0" }}
              position="top"
            >
              <Grid container spacing={3} style={{ marginTop: "1vw" }}>
                <Grid
                  container
                  spacing={12}
                  style={{ marginTop: "1vw", marginLeft: "1vw" }}
                >
                  <label htmlFor="tomado">F. Tomado</label>
                  <RadioButton
                    inputId="tomado"
                    value="tomado"
                    name="fecha"
                    onChange={(e) => setRadio(e.value)}
                    checked={radio === "tomado"}
                    style={{ marginTop: "0.2vw", marginLeft: "0.5vw" }}
                  />
                  <label style={{ marginLeft: "1vw" }} htmlFor="pasado">
                    F. Pasado
                  </label>
                  <RadioButton
                    inputId="pasado"
                    value="pasado"
                    name="fecha"
                    onChange={(e) => setRadio(e.value)}
                    checked={radio === "pasado"}
                    style={{ marginTop: "0.2vw", marginLeft: "0.5vw" }}
                  />
                  <label style={{ marginLeft: "1vw" }} htmlFor="salida">
                    F. Salida
                  </label>
                  <RadioButton
                    inputId="salida"
                    value="salida"
                    name="fecha"
                    onChange={(e) => setRadio(e.value)}
                    checked={radio === "salida"}
                    style={{ marginTop: "0.2vw", marginLeft: "0.5vw" }}
                  />
                  <label style={{ marginLeft: "1vw" }} htmlFor="llegada">
                    F. Llegada
                  </label>
                  <RadioButton
                    inputId="llegada"
                    value="llegada"
                    name="fecha"
                    onChange={(e) => setRadio(e.value)}
                    checked={radio === "llegada"}
                    style={{ marginTop: "0.2vw", marginLeft: "0.5vw" }}
                  />
                  <label style={{ marginLeft: "1vw" }} htmlFor="fin">
                    F. Fin
                  </label>
                  <RadioButton
                    inputId="fin"
                    value="fin"
                    name="fecha"
                    onChange={(e) => setRadio(e.value)}
                    checked={radio === "fin"}
                    style={{ marginTop: "0.2vw", marginLeft: "0.5vw" }}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <TextField
                    id="desde"
                    label="Desde"
                    type="datetime-local"
                    fullWidth
                    variant="standard"
                    //onKeyDown={handleEnter}
                    value={fechas && fechas.desde ? fechas.desde : null}
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      setFechas({
                        ...fechas,
                        desde: e.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    id="hasta"
                    label="Hasta"
                    type="datetime-local"
                    fullWidth
                    variant="standard"
                    //onKeyDown={handleEnter}
                    value={fechas && fechas.hasta ? fechas.hasta : null}
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      setFechas({
                        ...fechas,
                        hasta: e.target.value,
                      });
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={3}
                  style={{ paddingLeft: 0, marginTop: "0em" }}
                >
                  <Autocomplete
                    id="agrupar"
                    fullWidth
                    options={agrupar}
                    defaultValue={grupo ? agrupar[grupo - 1] : null}
                    disablePortal
                    getOptionLabel={(option) => option.nombre}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Agrupar por"
                        variant="standard"
                      />
                    )}
                    //onKeyDown={handleEnter}
                    onChange={(event, value) => {
                      if (value) {
                        setGrupo(value.id);
                      } else {
                        setGrupo(null);
                      }
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={6}
                  md={3}
                  style={{ paddingLeft: "0vw", marginTop: "-1vw" }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{ marginLeft: "1.5vw", marginRight: "2.2vw" }}
                        inputId="coseguro"
                        onChange={(e) => {
                          setCoseguro(e.target.checked);
                        }}
                        checked={coseguro ? coseguro : false}
                      />
                    }
                    label="Coseguros"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{ marginLeft: "1.5vw", marginRight: "2.2vw" }}
                        inputId="pediatrico"
                        onChange={(e) => {
                          setPediatrico(e.target.checked);
                        }}
                        checked={pediatrico ? pediatrico : false}
                      />
                    }
                    label="Pediátricos"
                  />
                </Grid>

                <Grid item xs={12} md={2}>
                  <TextField
                    id="titular"
                    label="ID Titular"
                    fullWidth
                    variant="standard"
                    type="number"
                    value={titularId ? titularId : ""}
                    disabled={titularNombre !== null && titularNombre !== ""}
                    //onKeyDown={handleEnter}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      setTitularId(e.target.value);
                      setTitularSeleccionado(null);
                      setRows(null);
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <TextField
                    id="razonSearch"
                    label="Apellido y Nombre o Razón Social"
                    fullWidth
                    value={titularNombre ? titularNombre : ""}
                    variant="standard"
                    disabled={
                      titularId !== null && titularId !== "" && titularId !== 0
                    }
                    //onKeyDown={handleEnter}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      setTitularNombre(e.target.value);
                      setTitularSeleccionado(null);
                      setRows(null);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={1}>
                  <Button
                    icon="pi pi-search"
                    className="p-button-rounded p-button-success"
                    onClick={() => buscarAfiliados()}
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <Autocomplete
                    id="razon"
                    fullWidth
                    options={rows}
                    disabled={!rows}
                    noOptionsText={"No hay opciones"}
                    style={{ marginLeft: ".8vw", color: "green" }}
                    //defaultValue={grupo ? agrupar[grupo - 1] : null}
                    value={titularSeleccionado}
                    disablePortal
                    getOptionLabel={(option) => option.nombre_razonsocial}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Seleccione el Afiliado"
                        variant="standard"
                      />
                    )}
                    onChange={(event, value) => {
                      if (value) {
                        setTitularSeleccionado(value);
                        setTitularId(value.id_titular);
                        setTitularNombre(null);
                      } else {
                        setTitularSeleccionado(null);
                      }
                    }}
                  />
                </Grid>
                {/*
              <Grid
                item
                xs={12}
                md={12}
                style={{ paddingLeft: 0, marginTop: "0em" }}
              >
                {!rows ? (
                  <ProgressBar
                    mode="indeterminate"
                    style={{ height: "6px" }}
                  ></ProgressBar>
                ) : null}
              </Grid>
                */}
                {/*
              <Grid
                item
                xs={12}
                md={12}
                style={{ paddingLeft: 0, marginTop: "-2vw" }}
              >
                <Tag style={{ marginLeft: ".8vw" }} value="Filtros"></Tag>
              </Grid>
              */}
                <Grid
                  item
                  xs={12}
                  md={2}
                  style={{ paddingLeft: 0, marginTop: "-1vw" }}
                >
                  <Autocomplete
                    id="colores"
                    multiple={true}
                    fullWidth
                    options={colores}
                    noOptionsText={"No hay opciones"}
                    style={{ marginLeft: ".8vw" }}
                    //defaultValue={grupo ? agrupar[grupo - 1] : null}
                    //value={val}
                    disablePortal
                    getOptionLabel={(option) => option.nombre}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Colores"
                        variant="standard"
                      />
                    )}
                    //onKeyDown={handleEnter}
                    onChange={(event, value) => {
                      if (value) {
                        setColor(value);
                      } else {
                        setColor(null);
                      }
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={3}
                  style={{
                    paddingLeft: 0,
                    marginTop: "-1vw",
                    marginRight: "1vw",
                  }}
                >
                  <Autocomplete
                    id="grupos"
                    fullWidth
                    options={grupos}
                    noOptionsText={"No hay opciones"}
                    style={{ marginLeft: ".8vw" }}
                    //defaultValue={grupo ? agrupar[grupo - 1] : null}
                    //value={val}
                    disablePortal
                    getOptionLabel={(option) => option.nombre}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Grupos"
                        variant="standard"
                      />
                    )}
                    //onKeyDown={handleEnter}
                    onChange={(event, value) => {
                      if (value) {
                        setGroup(value.id);
                      } else {
                        setGroup(null);
                      }
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={2}
                  md={1}
                  style={{ paddingLeft: "0vw", marginTop: "-1vw" }}
                >
                  <Checkbox
                    style={{ marginLeft: ".5vw", marginTop: "2.2vw" }}
                    inputId="movilPropioCheck"
                    onChange={(e) => {
                      setMovilPropioCheck(e.target.checked);
                    }}
                    checked={movilPropioCheck ? movilPropioCheck : false}
                    disabled={movilDerivadoCheck}
                  ></Checkbox>
                </Grid>

                <Grid
                  item
                  xs={10}
                  md={2}
                  style={{
                    marginLeft: "-3vw",
                    marginTop: "-1vw",
                    marginRight: "1vw",
                  }}
                >
                  <Autocomplete
                    id="moviles"
                    fullWidth
                    options={moviles}
                    disabled={!movilPropioCheck}
                    noOptionsText={"No hay opciones"}
                    //defaultValue={grupo ? agrupar[grupo - 1] : null}
                    //value={val}
                    disablePortal
                    getOptionLabel={(option) => option.nombre}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Móviles Propios"
                        variant="standard"
                      />
                    )}
                    //onKeyDown={handleEnter}
                    onChange={(event, value) => {
                      if (value) {
                        setMovilPropio(value.id);
                      } else {
                        setMovilPropio(null);
                      }
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={2}
                  md={1}
                  style={{ paddingLeft: "0vw", marginTop: "-1vw" }}
                >
                  <Checkbox
                    style={{ marginLeft: ".5vw", marginTop: "2.2vw" }}
                    inputId="movilDerivadoCheck"
                    onChange={(e) => {
                      setMovilDerivadoCheck(e.target.checked);
                    }}
                    checked={movilDerivadoCheck ? movilDerivadoCheck : false}
                    disabled={movilPropioCheck}
                  ></Checkbox>
                </Grid>

                <Grid
                  item
                  xs={10}
                  md={3}
                  style={{
                    marginLeft: "-3vw",
                    paddingLeft: 0,
                    marginTop: "-1vw",
                  }}
                >
                  <Autocomplete
                    id="derivados"
                    fullWidth
                    options={movilesDerivados}
                    disabled={!movilDerivadoCheck}
                    noOptionsText={"No hay opciones"}
                    //defaultValue={grupo ? agrupar[grupo - 1] : null}
                    //value={val}
                    disablePortal
                    getOptionLabel={(option) =>
                      option.nombre + " (" + option.id + " )"
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Móviles Derivados"
                        variant="standard"
                      />
                    )}
                    //onKeyDown={handleEnter}
                    onChange={(event, value) => {
                      if (value) {
                        setMovilDerivado(value.id);
                      } else {
                        setMovilDerivado(null);
                      }
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={3}
                  style={{ paddingLeft: 0, marginTop: "-1vw" }}
                >
                  <Autocomplete
                    id="bases"
                    fullWidth
                    options={bases}
                    noOptionsText={"No hay opciones"}
                    style={{ marginLeft: ".8vw" }}
                    //defaultValue={grupo ? agrupar[grupo - 1] : null}
                    //value={val}
                    disablePortal
                    getOptionLabel={(option) => option.nombre}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Bases Operativas"
                        variant="standard"
                      />
                    )}
                    //onKeyDown={handleEnter}
                    onChange={(event, value) => {
                      if (value) {
                        setBase(value.id);
                      } else {
                        setBase(null);
                      }
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={3}
                  style={{ paddingLeft: 0, marginTop: "-1vw" }}
                >
                  <Autocomplete
                    id="medicos"
                    fullWidth
                    options={medicos}
                    noOptionsText={"No hay opciones"}
                    style={{ marginLeft: ".8vw" }}
                    //defaultValue={grupo ? agrupar[grupo - 1] : null}
                    //value={val}
                    disablePortal
                    getOptionLabel={(option) => option.nombre}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Médicos"
                        variant="standard"
                      />
                    )}
                    //onKeyDown={handleEnter}
                    onChange={(event, value) => {
                      if (value) {
                        setMedico(value.id);
                      } else {
                        setMedico(null);
                      }
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={3}
                  style={{ paddingLeft: 0, marginTop: "-1vw" }}
                >
                  <Autocomplete
                    id="choferes"
                    fullWidth
                    options={choferes}
                    noOptionsText={"No hay opciones"}
                    style={{ marginLeft: ".8vw" }}
                    //defaultValue={grupo ? agrupar[grupo - 1] : null}
                    //value={val}
                    disablePortal
                    getOptionLabel={(option) => option.nombre}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choferes"
                        variant="standard"
                      />
                    )}
                    //onKeyDown={handleEnter}
                    onChange={(event, value) => {
                      if (value) {
                        setChofer(value.id);
                      } else {
                        setChofer(null);
                      }
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={3}
                  style={{ paddingLeft: 0, marginTop: "-1vw" }}
                >
                  <Autocomplete
                    id="enfermeros"
                    fullWidth
                    options={enfermeros}
                    noOptionsText={"No hay opciones"}
                    style={{ marginLeft: ".8vw" }}
                    //defaultValue={grupo ? agrupar[grupo - 1] : null}
                    //value={val}
                    disablePortal
                    getOptionLabel={(option) => option.nombre}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Enfermeros"
                        variant="standard"
                      />
                    )}
                    //onKeyDown={handleEnter}
                    onChange={(event, value) => {
                      if (value) {
                        setEnfermero(value.id);
                      } else {
                        setEnfermero(null);
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={2}>
                  <TextField
                    id="dni"
                    label="D.N.I."
                    fullWidth
                    variant="standard"
                    type="number"
                    value={dni ? dni : ""}
                    //onKeyDown={handleEnter}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      setDni(e.target.value);
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <TextField
                    id="domicilio"
                    label="Domicilio"
                    fullWidth
                    variant="standard"
                    value={domicilio ? domicilio : ""}
                    disabled={
                      titularId !== null && titularId !== "" && titularId !== 0
                    }
                    //onKeyDown={handleEnter}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      setDomicilio(e.target.value);
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <Autocomplete
                    id="empresas"
                    fullWidth
                    options={empresas}
                    noOptionsText={"No hay opciones"}
                    style={{ marginLeft: ".8vw" }}
                    //defaultValue={grupo ? agrupar[grupo - 1] : null}
                    //value={val}
                    disablePortal
                    getOptionLabel={(option) => option.nombre}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Empresas"
                        variant="standard"
                      />
                    )}
                    //onKeyDown={handleEnter}
                    onChange={(event, value) => {
                      if (value) {
                        setEmpresa(value.id);
                      } else {
                        setEmpresa(null);
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Dialog>
          ) : null}
        </Fragment>
      ) : null}
      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
