import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
//import "./example-styles.css";
//import "./styles.css";
//import { Responsive, WidthProvider } from "react-grid-layout";
import { AlertContext } from "../../../context/AlertContext";
import { ConfiguracionContext } from "../../../context/ConfiguracionContext";
import Spinner from "../Spinner";
import { AuthContext } from "../../../context/AuthContext";
import { ServiciosContext } from "../../../context/ServiciosContext";
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
//import NewWindow from "react-new-window";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import detectZoom from "detect-zoom";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { InputText } from "primereact/inputtext";
import "../../../assets/css/DataTableDemo.css";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import classNames from "classnames";
import { Checkbox } from "primereact/checkbox";
import { Tooltip } from "primereact/tooltip";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { NumericFormat } from "react-number-format";
import ButtonMui from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import FormControlLabel from "@mui/material/FormControlLabel";
import { RadioButton } from "primereact/radiobutton";
import hcOffcanvasNav from "hc-offcanvas-nav";
import { saveAs } from "file-saver";
import { Navigate, useNavigate } from "react-router-dom";
import Barra from "../Barra";

export default function Tripulaciones(props) {
  //const classes = useStyles();

  const [id, setId] = useState(null);
  const [value, setValue] = useState(0);
  const [filter, setFilter] = useState(null);
  const [loop, setLoop] = useState(null);
  const [resolution, setResolution] = useState(null);
  const [notificacion, setNotificacion] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [modalNotificacion, setModalNotificacion] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [tripulacion, guardarTripulacion] = useState(null);
  const [visible, setVisible] = useState(false);
  const toast = useRef(null);
  const toastWarm = useRef(null);
  const [movilesArray, setMovilesArray] = useState(null);
  const [telefonosArray, setTelefonosArray] = useState(null);
  const [almacenesArray, setAlmacenesArray] = useState(null);
  const [medicosArray, setMedicosArray] = useState(null);
  const [choferesArray, setChoferesArray] = useState(null);
  const [enfermerosArray, setEnfermerosArray] = useState(null);
  const [openMedico, setOpenMedico] = useState(false);
  const [openChofer, setOpenChofer] = useState(false);
  const [openEnfermero, setOpenEnfermero] = useState(false);
  const [cambiarMedico, setCambiarMedico] = useState(false);
  const [cambiarChofer, setCambiarChofer] = useState(false);
  const [cambiarEnfermero, setCambiarEnfermero] = useState(false);
  const [tripulacionAnterior, setTripulacionAnterior] = useState(null);
  const [updateTripulacion, setUpdateTripulacion] = useState(false);
  const [band, setBand] = useState(false);
  const [visibleModificaciones, setVisibleModificaciones] = useState(false);
  const [tripulacionOriginal, guardarTripulacionOriginal] = useState(null);
  const [radio, setRadio] = useState("armadas");
  let navigate = useNavigate();

  const exportColumns = [
    { title: "ID", dataKey: "id" },
    { title: "Móvil", dataKey: "movil" },
    { title: "Base", dataKey: "base" },
  ];

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso,
    statusAcceso3,
    telefono,
    telefonos,
    almacen,
    almacenes,
    medicos,
    choferes,
    enfermeros,
    colores,
    bases,
    moviles,
    obtenerAcceso,
    obtenerColores,
    obtenerTelefono,
    obtenerBases,
    obtenerMoviles,
    obtenerMedicos,
    obtenerChoferes,
    obtenerEnfermeros,
    obtenerMovilesPropiosLiberados,
    obtenerMedicosLiberados,
    obtenerChoferesLiberados,
    obtenerEnfermerosLiberados,
    obtenerTelefonosLiberados,
    obtenerAlmacenesLiberados,
    obtenerMovil,
    obtenerMedico,
    obtenerChofer,
    obtenerEnfermero,
    guardarMoviles,
    guardarAlmacenes,
    guardarTelefonos,
    guardarMedicos,
    guardarChoferes,
    guardarEnfermeros,
    obtenerAcceso3,
  } = configuracionContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    resumen,
    refrescar,
    tripulaciones,
    tripulacionesCerradas,
    estadoTomado,
    tripulacionModificada,
    statusEstadoMoviles,
    obtenerTripulaciones,
    obtenerTripulacionesCerradas,
    setEstadoTomado,
    guardarServicio,
    enviarNotificaciones,
    obtenerTripulacion,
    agregarTripulacion,
    actualizarTripulacion,
    actualizarTripulacionCambiada,
    verificarEstadoMoviles,
    guardarTripulacionModificada,
    modificarTripulacionCerrada,
    setStatusEstadoMoviles,
  } = serviciosContext;

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({ usuarioId: state.usuario.id, layout: "Tripulaciones" });
      obtenerAcceso3({
        usuarioId: state.usuario.id,
        layout: "ModificacionesTripulaciones",
      });
    }
  }, [state.id]);

  setTimeout(() => {
    if (statusAcceso === 400) {
      accesoDenegado();
    }
  }, 3000);

  useEffect(() => {
    obtenerTripulaciones({ estado: false });
    obtenerBases({ todos: false });
    setUpdateTripulacion(true);
    verificarEstadoMoviles();
  }, []);

  if (
    tripulacionOriginal === null &&
    tripulacionModificada !== undefined &&
    tripulacionModificada !== null
  ) {
    guardarTripulacionOriginal(tripulacionModificada);
  }

  if (tripulaciones && updateTripulacion && !band) {
    console.log('1')
    obtenerMovilesPropiosLiberados({ tripulaciones });
    obtenerTelefonosLiberados({ todos: false, tripulaciones });
    obtenerAlmacenesLiberados({ todos: false, tripulaciones });
    obtenerMedicosLiberados({ todos: false, tripulaciones });
    obtenerChoferesLiberados({ todos: false, tripulaciones });
    obtenerEnfermerosLiberados({ todos: false, tripulaciones });
    setUpdateTripulacion(false);
    setBand(true);
  }

  if (moviles && moviles.length > 0 && !movilesArray && band) {
    console.log('2')
    setMovilesArray(moviles);
  }
  if (telefonos && telefonos.length > 0 && !telefonosArray && band) {
    console.log('3')
    setTelefonosArray(telefonos);
  }
  if (almacenes && almacenes.length > 0 && !almacenesArray && band) {
    console.log('4')
    setAlmacenesArray(almacenes);
  }
  if (medicos && medicos.length > 0 && !medicosArray && band) {
    console.log('5')
    setMedicosArray(medicos);
  }
  if (choferes && choferes.length > 0 && !choferesArray && band) {
    console.log('6')
    setChoferesArray(choferes);
  }
  if (enfermeros && enfermeros.length > 0 && !enfermerosArray && band) {
    console.log('7')
    setEnfermerosArray(enfermeros);
  }

  const editClick = () => {
    if (selectedRow) {
      let copyArray = [...movilesArray];

      if (
        movilesArray &&
        selectedRow.movilId !== null &&
        movilesArray.findIndex(
          (element) => element.id === selectedRow.movilId
        ) === -1
      ) {
        copyArray.push({
          id: selectedRow.movilId,
          nombre: selectedRow.movil,
        });

        copyArray.sort(function (a, b) {
          if (a.nombre > b.nombre) {
            return 1;
          }
          if (a.nombre < b.nombre) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });

        setMovilesArray(copyArray);
      }

      if (
        telefonosArray &&
        selectedRow.telefonoId !== null &&
        telefonosArray.findIndex(
          (element) => element.id === selectedRow.telefonoId
        ) === -1
      ) {
        let copyArray = [...telefonosArray];
        copyArray.push({
          id: selectedRow.telefonoId,
          nombre: selectedRow.telefono,
        });

        copyArray.sort(function (a, b) {
          if (a.nombre > b.nombre) {
            return 1;
          }
          if (a.nombre < b.nombre) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });

        setTelefonosArray(copyArray);
      }

      if (
        almacenesArray &&
        selectedRow.almacenId !== null &&
        almacenesArray.findIndex(
          (element) => element.id === selectedRow.almacenId
        ) === -1
      ) {
        let copyArray = [...almacenesArray];
        copyArray.push({
          id: selectedRow.almacenId,
          nombre: selectedRow.almacen,
        });

        copyArray.sort(function (a, b) {
          if (a.nombre > b.nombre) {
            return 1;
          }
          if (a.nombre < b.nombre) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });

        setAlmacenesArray(copyArray);
      }

      if (
        medicosArray &&
        selectedRow.medicoId !== null &&
        medicosArray.findIndex(
          (element) => element.id === selectedRow.medicoId
        ) === -1
      ) {
        let copyArray = [...medicosArray];
        copyArray.push({
          id: selectedRow.medicoId,
          nombre: selectedRow.medico,
        });

        copyArray.sort(function (a, b) {
          if (a.nombre > b.nombre) {
            return 1;
          }
          if (a.nombre < b.nombre) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });

        setMedicosArray(copyArray);
      }

      if (
        choferesArray &&
        selectedRow.choferId !== null &&
        choferesArray.findIndex(
          (element) => element.id === selectedRow.choferId
        ) === -1
      ) {
        let copyArray = [...choferesArray];
        copyArray.push({
          id: selectedRow.choferId,
          nombre: selectedRow.chofer,
        });

        copyArray.sort(function (a, b) {
          if (a.nombre > b.nombre) {
            return 1;
          }
          if (a.nombre < b.nombre) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });

        setChoferesArray(copyArray);
      }

      if (
        enfermerosArray &&
        selectedRow.enfermeroId !== null &&
        enfermerosArray.findIndex(
          (element) => element.id === selectedRow.enfermeroId
        ) === -1
      ) {
        let copyArray = [...enfermerosArray];
        copyArray.push({
          id: selectedRow.enfermeroId,
          nombre: selectedRow.enfermero,
        });
        copyArray.sort(function (a, b) {
          if (a.nombre > b.nombre) {
            return 1;
          }
          if (a.nombre < b.nombre) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });

        setEnfermerosArray(copyArray);
      }

      guardarTripulacion({
        id: selectedRow.id,
        baseId: selectedRow.baseId,
        telefonoId: selectedRow.telefonoId,
        movilId: selectedRow.movilId,
        medicoId: selectedRow.medicoId,
        medicoIngreso: selectedRow.medicoIngreso,
        medicoSalida: selectedRow.medicoSalida,
        choferId: selectedRow.choferId,
        choferIngreso: selectedRow.choferIngreso,
        choferSalida: selectedRow.choferSalida,
        enfermeroId: selectedRow.enfermeroId,
        enfermeroIngreso: selectedRow.enfermeroIngreso,
        enfermeroSalida: selectedRow.enfermeroSalida,
        servicioId: selectedRow.servicioId,
        activo: selectedRow.activo,
        localizacion: selectedRow.localizacion,
        cerrar: false,
      });

      setTripulacionAnterior(selectedRow);

      //console.log(selectedRow);
      setVisible(true);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar una tripulación.",
        life: 3000,
      });
    }
  };

  const addClick = () => {
    //navigate(`/tripulacion/0`);
    setSelectedRow(null);
    setMovilesArray(null);
    setTelefonosArray(null);
    setAlmacenesArray(null);
    setMedicosArray(null);
    setChoferesArray(null);
    setEnfermerosArray(null);
    guardarMoviles(null);
    guardarTelefonos(null);
    guardarAlmacenes(null)
    guardarMedicos(null);
    guardarChoferes(null);
    guardarEnfermeros(null);
    setUpdateTripulacion(true);
    setBand(false);
    verificarEstadoMoviles()
 
    guardarTripulacion({
      id: 0,
      baseId: 0,
      movilId: 0,
      telefonoId: 0,
      medicoId: 0,
      medicoIngreso: "",
      medicoSalida: "",
      choferId: 0,
      choferIngreso: "",
      choferSalida: "",
      enfermeroId: 0,
      enfermeroIngreso: "",
      enfermeroSalida: "",
      activo: true,
      localizacion: true,
      cerrar: false,
    });

    setVisible(true);
  };

  const exitMenu = (e) => {
    navigate(`/`);
  };

  const exitClick = (e) => {
    navigate(`/`);
  };

  const onHide = (e) => {
    setSelectedRow(null);
    setMovilesArray(null);
    setTelefonosArray(null);
    setAlmacenesArray(null);
    setMedicosArray(null);
    setChoferesArray(null);
    setEnfermerosArray(null);
    setUpdateTripulacion(true);
    setVisible(false);
  };

  const handleClickOpenMedico = () => {
    toastWarm.current.show({
      severity: "warn",
      sticky: true,
      content: (
        <div
          className="p-flex p-flex-column"
          style={{ flex: "1", marginLeft: "1em" }}
        >
          <div className="p-text-center">
            <i
              className="pi pi-exclamation-triangle"
              style={{ fontSize: "3rem" }}
            ></i>
            <h4>{`Confirmar el cambio de médico en la tripulación ?`}</h4>
          </div>
          <div className="p-grid p-fluid">
            <div className="p-col-6">
              <Button
                type="button"
                label="Si"
                className="p-button-success"
                onClick={(e) => handleCambiarMedicoSi()}
              />
            </div>
            <div className="p-col-6">
              <Button
                type="button"
                label="No"
                className="p-button-danger"
                style={{ marginTop: "1em" }}
                onClick={(e) => handleCambiarMedicoNo()}
              />
            </div>
          </div>
        </div>
      ),
    });
  };

  const handleClickOpenChofer = () => {
    toastWarm.current.show({
      severity: "warn",
      sticky: true,
      content: (
        <div
          className="p-flex p-flex-column"
          style={{ flex: "1", marginLeft: "1em" }}
        >
          <div className="p-text-center">
            <i
              className="pi pi-exclamation-triangle"
              style={{ fontSize: "3rem" }}
            ></i>
            <h4>{`Confirmar el cambio de chofer en la tripulación ?`}</h4>
          </div>
          <div className="p-grid p-fluid">
            <div className="p-col-6">
              <Button
                type="button"
                label="Si"
                className="p-button-success"
                onClick={(e) => handleCambiarChoferSi()}
              />
            </div>
            <div className="p-col-6">
              <Button
                type="button"
                label="No"
                className="p-button-danger"
                style={{ marginTop: "1em" }}
                onClick={(e) => handleCambiarChoferNo()}
              />
            </div>
          </div>
        </div>
      ),
    });
  };

  const handleClickOpenEnfermero = () => {
    toastWarm.current.show({
      severity: "warn",
      sticky: true,
      content: (
        <div
          className="p-flex p-flex-column"
          style={{ flex: "1", marginLeft: "1em" }}
        >
          <div className="p-text-center">
            <i
              className="pi pi-exclamation-triangle"
              style={{ fontSize: "3rem" }}
            ></i>
            <h4>{`Confirmar el cambio de enfermero en la tripulación ?`}</h4>
          </div>
          <div className="p-grid p-fluid">
            <div className="p-col-6">
              <Button
                type="button"
                label="Si"
                className="p-button-success"
                onClick={(e) => handleCambiarEnfermeroSi()}
              />
            </div>
            <div className="p-col-6">
              <Button
                type="button"
                label="No"
                className="p-button-danger"
                style={{ marginTop: "1em" }}
                onClick={(e) => handleCambiarEnfermeroNo()}
              />
            </div>
          </div>
        </div>
      ),
    });
  };

  const handleCambiarMedicoSi = () => {
    setCambiarMedico(true);
    toastWarm.current.clear();
  };

  const handleCambiarMedicoNo = () => {
    toastWarm.current.clear();
  };

  const handleCambiarChoferSi = () => {
    setCambiarChofer(true);
    toastWarm.current.clear();
  };

  const handleCambiarChoferNo = () => {
    toastWarm.current.clear();
  };

  const handleCambiarEnfermeroSi = () => {
    setCambiarEnfermero(true);
    toastWarm.current.clear();
  };

  const handleCambiarEnfermeroNo = () => {
    toastWarm.current.clear();
  };

  const aceptarClick = (e) => {
    e.preventDefault();

    //Validar
    if (tripulacion.baseId === 0) {
      mensajeAlerta("La base es necesaria !.", "error");
      return;
    }

    if (tripulacion.movilId === 0) {
      mensajeAlerta("El móvil es necesario !.", "error");
      return;
    }

    if (
      tripulacion.medicoId === 0 &&
      tripulacion.choferId === 0 &&
      tripulacion.enfermeroId === 0
    ) {
      mensajeAlerta("Es necesario cargar al menos un tripulante !.", "error");
      return;
    }

    // Llamar al context
    if (selectedRow) {
      if (
        tripulacionAnterior.baseId !== tripulacion.baseId ||
        tripulacionAnterior.movilId !== tripulacion.movilId ||
        tripulacionAnterior.medicoId !== tripulacion.medicoId ||
        tripulacionAnterior.choferId !== tripulacion.choferId ||
        tripulacionAnterior.enfermeroId !== tripulacion.enfermeroId ||
        cambiarMedico ||
        cambiarChofer ||
        cambiarEnfermero
      ) {
        actualizarTripulacionCambiada({
          tripulacion,
          usuario: state.usuario.usuario,
          cambiarMedico,
          cambiarChofer,
          cambiarEnfermero,
          tripulacionAnterior,
        });
      } else {
        actualizarTripulacion({ tripulacion, usuario: state.usuario.usuario });
      }
    } else {
      agregarTripulacion({ tripulacion, usuario: state.usuario.usuario });
    }
    setTimeout(() => {
      obtenerTripulaciones({ estado: false });
      obtenerBases({ todos: false });
      setSelectedRow(null);
      setMovilesArray(null);
      setTelefonosArray(null);
      setAlmacenesArray(null);
      setMedicosArray(null);
      setChoferesArray(null);
      setEnfermerosArray(null);
      guardarMoviles(null);
      guardarTelefonos(null);
      guardarMedicos(null);
      guardarChoferes(null);
      guardarEnfermeros(null);
      setUpdateTripulacion(true);
      setBand(false);
      setCambiarChofer(false);
      setCambiarEnfermero(false);
      setCambiarMedico(false);
    }, 2000);

    setVisible(false);

    //exitClick();
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const openModalNotificacion = (rowData) => {
    if (rowData.telefonoId !== null) {
      setNotificacion(null);
      obtenerTelefono({ idTelefono: rowData.telefonoId });
      setModalNotificacion(true);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Teléfono",
        detail: `La tripulación no tiene teléfono asignado.`,
        life: 3000,
      });
    }
  };

  const hideModalNotificacion = () => {
    setModalNotificacion(false);
  };

  const enviarNotificacion = () => {
    if (
      !notificacion ||
      notificacion.title === undefined ||
      notificacion.title === null ||
      notificacion.title === ""
    ) {
      mensajeAlerta("El título es necesario !.", "error");
      return;
    }

    if (
      !notificacion ||
      notificacion.body === undefined ||
      notificacion.body === null ||
      notificacion.body === ""
    ) {
      mensajeAlerta("El cuerpo es necesario !.", "error");
      return;
    }

    enviarNotificaciones({ notificacion, token: telefono.token });
    hideModalNotificacion();
  };

  const notificacionDialogFooter = (
    <Fragment>
      <Button
        label="Enviar"
        icon="pi pi-check"
        className="p-button-text"
        onClick={(e) => enviarNotificacion()}
      />
      <Button
        label="Salir"
        icon="pi pi-times"
        className="p-button-text"
        onClick={(e) => hideModalNotificacion()}
      />
    </Fragment>
  );

  const idTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">ID</span>
        {rowData.id}
      </Fragment>
    );
  };

  const movilTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Móvil</span>
        {rowData.movilId}
      </Fragment>
    );
  };

  const baseTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Base</span>
        {rowData.base}
      </Fragment>
    );
  };

  const telefonoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Teléfono</span>
        {rowData.telefono}
      </Fragment>
    );
  };

  const almacenTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Maletín</span>
        {rowData.almacen}
      </Fragment>
    );
  };

  const medicoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Médico</span>
        {rowData.medico}
      </Fragment>
    );
  };

  const choferTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Chofer</span>
        {rowData.chofer}
      </Fragment>
    );
  };

  const enfermeroTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Enfermero</span>
        {rowData.enfermero}
      </Fragment>
    );
  };

  const activoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Activo</span>
        <Checkbox checked={rowData.activo}></Checkbox>
      </Fragment>
    );
  };

  const localizacionTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Localización</span>
        <Checkbox checked={rowData.localizacion}></Checkbox>
      </Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <Fragment>
        {radio === "armadas" ? (
          <Button
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingTop: 0,
              paddingBottom: 0,
            }}
            icon="pi pi-bell"
            className="p-button-success p-button-text"
            onClick={() => openModalNotificacion(rowData)}
            tooltip="Enviar Notificación"
            tooltipOptions={{
              position: "bottom",
              mouseTrack: true,
              mouseTrackTop: 15,
              className: "tooltip",
            }}
          />
        ) : null}
        {radio === "cerradas" ? (
          <Button
            style={{
              margin: 0,
              padding: 0,
            }}
            icon="pi pi-pencil"
            className="p-button-text"
            onClick={(e) => {
              guardarTripulacionModificada(null);
              guardarTripulacionOriginal(null);
              modificaciones(rowData);
            }}
            tooltip="Modificaciones"
            tooltipOptions={{
              position: "bottom",
              mouseTrack: true,
              mouseTrackTop: 15,
              className: "tooltip",
            }}
          />
        ) : null}
      </Fragment>
    );
  };

  const cerradoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Cerrado</span>
        <Checkbox checked={rowData.cerrado}></Checkbox>
      </Fragment>
    );
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, tripulaciones);
        doc.save("tripulaciones.pdf");
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tripulaciones);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "tripulaciones");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      saveAs(
        data,
        fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
      );
    });
  };

  const header = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={exportExcel}
          className="p-button-success p-mr-2"
          data-pr-tooltip="XLS"
        />
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={exportPdf}
          className="p-button-warning p-mr-2"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
        />
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value="Tripulaciones"></Tag>
      </div>
    </Fragment>
  );

  const renderHeader = () => {
    return (
      <div>
        <Tag value="Tripulación"></Tag>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div>
        <ButtonMui
          onClick={(e) => aceptarClick(e)}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={(e) => onHide()}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const modificaciones = (rowData) => {
    if (statusAcceso3 !== 200) {
      mensajeAlerta(
        "No tiene permiso para acceder a esta pantalla !.",
        "error"
      );
      return;
    }

    obtenerTripulacion({ idTripulacion: rowData.id });
    setVisibleModificaciones(true);
  };

  const renderHeaderModificar = () => {
    return (
      <div>
        <Tag value="Modificaciones del Servicio"></Tag>
      </div>
    );
  };

  const renderFooterModificar = () => {
    return (
      <div>
        <Button
          label="Confirmar"
          icon="pi pi-check"
          onClick={() => handleModificar()}
          className="p-button-text"
          autoFocus
        />
        <Button
          label="Volver"
          icon="pi pi-times"
          onClick={() => handleCloseModificar()}
          className="p-button-text"
        />
      </div>
    );
  };

  const handleCloseModificar = () => {
    setVisibleModificaciones(false);
  };

  const handleModificar = () => {
    //Validar
    if (tripulacionModificada.baseId === 0) {
      mensajeAlerta("La base es necesaria !.", "error");
      return;
    }

    if (tripulacionModificada.movilId === 0) {
      mensajeAlerta("El móvil es necesario !.", "error");
      return;
    }

    if (
      tripulacionModificada.medicoId === 0 &&
      tripulacionModificada.choferId === 0 &&
      tripulacionModificada.enfermeroId === 0
    ) {
      mensajeAlerta("Es necesario cargar al menos un tripulante !.", "error");
      return;
    }

    modificarTripulacionCerrada({
      tripulacionId: tripulacionModificada.id,
      tripulacionOriginal,
      tripulacionModificada,
      usuario: state.usuario.usuario,
      //coseguroAbonar,
      //servicioAnterior: servicioSeleccionado,
    });

    handleCloseModificar();
    exitClick();
  };

  //console.log(almacenesArray)
  //console.log(almacenesArray)

  return (
    <Fragment>
      <Barra />
      {statusAcceso === 0 || statusAcceso === 400 ? <Spinner /> : null}
      {statusAcceso === 200 ? (
        <Fragment>
          <Toast ref={toast} position="top-right" />
          <Toast ref={toastWarm} position="top-center" />
          {tripulaciones &&
          movilesArray &&
          telefonosArray &&
          almacenesArray &&
          medicosArray &&
          choferesArray &&
          enfermerosArray ? (
            <Fragment>
              <Grid item xs={12} sm={12} md={12}>
                {radio === "armadas" ? (
                  <Fragment>
                    <Button
                      icon="pi pi-plus"
                      tooltip="Agregar"
                      className="p-button-rounded p-button-help"
                      style={{ marginLeft: "0em" }}
                      onClick={(e) => addClick()}
                    />

                    <Button
                      icon="pi pi-pencil"
                      tooltip="Editar"
                      className="p-button-rounded p-button-danger"
                      style={{ marginLeft: "1em" }}
                      onClick={(e) => editClick()}
                    />
                  </Fragment>
                ) : null}
                <Button
                  icon="pi pi-times"
                  tooltip="Cerrar"
                  className="p-button-rounded p-button-primary"
                  style={{ marginLeft: "1em" }}
                  onClick={(e) => exitMenu()}
                />

                <label style={{ marginLeft: "2vw" }} htmlFor="armadas">
                  Armadas
                </label>
                <RadioButton
                  inputId="armadas"
                  value="armadas"
                  name="armadas"
                  onChange={(e) => {
                    setRadio(e.value);
                    obtenerTripulaciones({ estado: false });
                    obtenerBases({ todos: false });
                    setUpdateTripulacion(true);
                    setBand(false);
                  }}
                  checked={radio === "armadas"}
                  style={{ marginLeft: "0.5vw" }}
                />
                <label style={{ marginLeft: "2vw" }} htmlFor="cerradas">
                  Cerradas
                </label>
                <RadioButton
                  inputId="cerradas"
                  value="cerradas"
                  name="cerradas"
                  onChange={(e) => {
                    setRadio(e.value);
                    obtenerMoviles({ todos: false, propios: true });
                    obtenerMedicos({ todos: false });
                    obtenerChoferes({ todos: false });
                    obtenerEnfermeros({ todos: false });
                    obtenerTripulacionesCerradas();
                  }}
                  checked={radio === "cerradas"}
                  style={{ marginLeft: "0.5vw" }}
                />

                {radio === "armadas" ? (
                  <Fragment>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      style={{ align: "center", margin: "1em" }}
                    />
                    <div className="datatable-responsive-demo">
                      <div className="card">
                        <Tooltip
                          target=".export-buttons>button"
                          position="bottom"
                        />
                        <DataTable
                          value={tripulaciones}
                          selection={selectedRow}
                          onSelectionChange={(e) => setSelectedRow(e.value)}
                          selectionMode="single"
                          dataKey="id"
                          header={header}
                          className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                          style={{ fontSize: "small" }}
                          globalFilter={globalFilter}
                          emptyMessage="No hay datos."
                          paginator
                          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                          rows={50}
                          rowsPerPageOptions={[10, 20, 50]}
                          //paginatorLeft={paginatorLeft}
                          //paginatorRight={paginatorRight}
                            >
                          <Column
                            field="id"
                            header="ID"
                            body={idTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="movilId"
                            header="Móvil"
                            body={movilTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="base"
                            header="Base"
                            body={baseTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="telefono"
                            header="Teléfono"
                            body={telefonoTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="almacen"
                            header="Maletín"
                            body={almacenTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="medico"
                            header="Médico"
                            body={medicoTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="chofer"
                            header="Chofer"
                            body={choferTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="enfermero"
                            header="Enfermero"
                            body={enfermeroTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="activo"
                            header="Activo"
                            body={activoTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="localizacion"
                            header="Localización"
                            body={localizacionTemplate}
                            sortable
                          ></Column>
                          <Column
                            className="colBotones"
                            body={actionBodyTemplate}
                          ></Column>
                        </DataTable>
                      </div>
                    </div>
                  </Fragment>
                ) : (
                  <Fragment>
                    {tripulacionesCerradas ? (
                      <Fragment>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          style={{ align: "center", margin: "1em" }}
                        />

                        <div className="datatable-responsive-demo">
                          <div className="card">
                            <Tooltip
                              target=".export-buttons>button"
                              position="bottom"
                            />
                            <DataTable
                              value={tripulacionesCerradas}
                              selection={selectedRow}
                              onSelectionChange={(e) => setSelectedRow(e.value)}
                              selectionMode="single"
                              dataKey="id"
                              header={header}
                              className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                              style={{ fontSize: "xx-small" }}
                              globalFilter={globalFilter}
                              emptyMessage="No hay datos."
                              paginator
                              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                              currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                              rows={10}
                              rowsPerPageOptions={[10, 20, 50]}
                              //paginatorLeft={paginatorLeft}
                              //paginatorRight={paginatorRight}
                            >
                              <Column
                                field="id"
                                header="ID"
                                body={idTemplate}
                                sortable
                              ></Column>
                              <Column
                                field="movilId"
                                header="Móvil"
                                body={movilTemplate}
                                sortable
                              ></Column>
                              <Column
                                field="base"
                                header="Base"
                                body={baseTemplate}
                                sortable
                              ></Column>
                              <Column
                                field="telefono"
                                header="Teléfono"
                                body={telefonoTemplate}
                                sortable
                              ></Column>
                              <Column
                                field="almacen"
                                header="Maletín"
                                body={almacenTemplate}
                                sortable
                              ></Column>
                              <Column
                                field="medico"
                                header="Médico"
                                body={medicoTemplate}
                                sortable
                              ></Column>
                              <Column
                                field="chofer"
                                header="Chofer"
                                body={choferTemplate}
                                sortable
                              ></Column>
                              <Column
                                field="enfermero"
                                header="Enfermero"
                                body={enfermeroTemplate}
                                sortable
                              ></Column>
                              <Column
                                field="activo"
                                header="Activo"
                                body={activoTemplate}
                                sortable
                              ></Column>
                              <Column
                                field="localizacion"
                                header="Localización"
                                body={localizacionTemplate}
                                sortable
                              ></Column>
                              <Column
                                className="colBotones"
                                body={actionBodyTemplate}
                              ></Column>
                            </DataTable>
                          </div>
                        </div>
                      </Fragment>
                    ) : (
                      <Spinner />
                    )}
                  </Fragment>
                )}
              </Grid>

              <Dialog
                visible={modalNotificacion}
                style={{ width: "450px" }}
                header="Notificaciones"
                modal
                closable={false}
                footer={notificacionDialogFooter}
                onHide={hideModalNotificacion}
              >
                <Grid container spacing={2} style={{ padding: "0" }}>
                  <Grid item xs={12} md={12} />
                  <Grid item xs={12} md={12}>
                    <TextField
                      id="titulo"
                      label="Título"
                      required
                      fullWidth
                      variant="standard"
                      size="small"
                      onChange={(e) =>
                        setNotificacion({
                          ...notificacion,
                          title: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      id="cuerpo"
                      label="Cuerpo"
                      required
                      fullWidth
                      variant="standard"
                      size="small"
                      onChange={(e) =>
                        setNotificacion({
                          ...notificacion,
                          body: e.target.value,
                        })
                      }
                    />
                  </Grid>
                </Grid>
              </Dialog>

              <Dialog
                header={renderHeader()}
                visible={visible}
                closable={false}
                footer={renderFooter()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "90vw", paddingBottom: "0" }}
                position="center"
              >
                <Grid container spacing={3} style={{ marginTop: "1vw" }}>
                  {selectedRow ? (
                    <Grid item xs={12} md={2}>
                      <TextField
                        value={selectedRow ? selectedRow.id : null}
                        required
                        id="id"
                        label="ID"
                        fullWidth
                        variant="standard"
                        disabled
                      />
                    </Grid>
                  ) : null}

                  <Grid item xs={12} md={4}>
                    <Autocomplete
                      id="base"
                      fullWidth
                      options={bases}
                      disablePortal
                      defaultValue={
                        bases && tripulacion && tripulacion.baseId !== undefined
                          ? bases[
                              bases.findIndex(
                                (item) => item.id === tripulacion.baseId
                              )
                            ]
                          : null
                      }
                      noOptionsText={"No hay opciones"}
                      getOptionLabel={(option) => option.nombre}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Bases"
                          variant="standard"
                        />
                      )}
                      //onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        if (value) {
                          guardarTripulacion({
                            ...tripulacion,
                            baseId: value.id,
                          });
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <Autocomplete
                      id="movil"
                      fullWidth
                      options={movilesArray}
                      disablePortal
                      defaultValue={
                        movilesArray &&
                        selectedRow &&
                        selectedRow.movilId !== undefined
                          ? movilesArray[
                              movilesArray.findIndex(
                                (item) => item.id === selectedRow.movilId
                              )
                            ]
                          : null
                      }
                      noOptionsText={"No hay opciones"}
                      getOptionLabel={(option) => option.nombre}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Móviles"
                          variant="standard"
                        />
                      )}
                      //onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        if (value) {
                          guardarTripulacion({
                            ...tripulacion,
                            movilId: value.id,
                          });
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <Autocomplete
                      id="telefono"
                      fullWidth
                      options={telefonosArray}
                      disablePortal
                      defaultValue={
                        telefonosArray &&
                        selectedRow &&
                        selectedRow.telefonoId !== undefined
                          ? telefonosArray[
                              telefonosArray.findIndex(
                                (item) => item.id === selectedRow.telefonoId
                              )
                            ]
                          : null
                      }
                      noOptionsText={"No hay opciones"}
                      getOptionLabel={(option) => option.nombre}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Teléfonos"
                          variant="standard"
                        />
                      )}
                      //onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        if (value) {
                          guardarTripulacion({
                            ...tripulacion,
                            telefonoId: value.id,
                            localizacion: true,
                          });
                        } else {
                          guardarTripulacion({
                            ...tripulacion,
                            telefonoId: 0,
                            localizacion: false,
                          });
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <Autocomplete
                      id="almacen"
                      fullWidth
                      options={almacenesArray}
                      disablePortal
                      defaultValue={
                        almacenesArray &&
                        selectedRow &&
                        selectedRow.almacenId !== undefined
                          ? almacenesArray[
                              almacenesArray.findIndex(
                                (item) => item.id === selectedRow.almacenId
                              )
                            ]
                          : null
                      }
                      noOptionsText={"No hay opciones"}
                      getOptionLabel={(option) => option.nombre}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Maletines"
                          variant="standard"
                        />
                      )}
                      //onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        if (value) {
                          guardarTripulacion({
                            ...tripulacion,
                            almacenId: value.id,
                          });
                        } else {
                          guardarTripulacion({
                            ...tripulacion,
                            almacenId: 0,
                          });
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Autocomplete
                      id="medico"
                      fullWidth
                      options={medicosArray}
                      disablePortal
                      defaultValue={
                        medicosArray &&
                        selectedRow &&
                        selectedRow.medicoId !== undefined
                          ? medicosArray[
                              medicosArray.findIndex(
                                (item) => item.id === selectedRow.medicoId
                              )
                            ]
                          : null
                      }
                      noOptionsText={"No hay opciones"}
                      disabled={
                        tripulacion &&
                        tripulacion.medicoIngreso !== "" &&
                        tripulacion.medicoIngreso !== null
                      }
                      getOptionLabel={(option) => option.nombre}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Médicos"
                          variant="standard"
                        />
                      )}
                      //onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        if (value) {
                          guardarTripulacion({
                            ...tripulacion,
                            medicoId: value.id,
                          });
                        } else {
                          guardarTripulacion({
                            ...tripulacion,
                            medicoId: 0,
                          });
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <TextField
                      id="medicoIngreso"
                      label="Ingreso"
                      type="datetime-local"
                      variant="standard"
                      disabled={
                        tripulacion &&
                        (tripulacion.medicoId === "" ||
                          tripulacion.medicoId === null ||
                          tripulacion.medicoId === 0 ||
                          (tripulacion.medicoIngreso !== "" &&
                            tripulacion.medicoIngreso !== null))
                          ? true
                          : false
                      }
                      noOptionsText={"No hay opciones"}
                      defaultValue={
                        selectedRow &&
                        selectedRow.medicoIngreso !== "" &&
                        selectedRow.medicoIngreso !== null
                          ? selectedRow.medicoIngreso
                          : null
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          guardarTripulacion({
                            ...tripulacion,
                            medicoIngreso: e.target.value,
                          });
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <TextField
                      id="medicoSalida"
                      label="Salida"
                      type="datetime-local"
                      variant="standard"
                      error={
                        tripulacion &&
                        tripulacion.medicoSalida !== null &&
                        tripulacion.medicoSalida !== "" &&
                        tripulacion.medicoSalida <= tripulacion.medicoIngreso
                      }
                      helperText={
                        tripulacion &&
                        tripulacion.medicoSalida !== null &&
                        tripulacion.medicoSalida !== "" &&
                        tripulacion.medicoSalida <= tripulacion.medicoIngreso
                          ? "Error en la fecha"
                          : tripulacion &&
                            tripulacion.servicioId !== null &&
                            tripulacion.servicioId !== 0
                          ? "En servicio"
                          : " "
                      }
                      disabled={
                        tripulacion &&
                        (tripulacion.medicoIngreso === null ||
                          tripulacion.medicoIngreso === "" ||
                          (tripulacion.medicoSalida !== null &&
                            tripulacion.medicoSalida !== "" &&
                            tripulacion.medicoSalida >
                              tripulacion.medicoIngreso) ||
                          (tripulacion.servicioId !== null &&
                            tripulacion.servicioId !== 0))
                          ? true
                          : false
                      }
                      defaultValue={
                        selectedRow &&
                        selectedRow.medicoSalida !== null &&
                        selectedRow.medicoSalida !== ""
                          ? selectedRow.medicoSalida
                          : null
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          guardarTripulacion({
                            ...tripulacion,
                            medicoSalida: e.target.value,
                          });
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={2} />
                  <Grid item xs={12} md={2}>
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      disableElevation
                      disabled={
                        tripulacion &&
                        (tripulacion.medicoSalida === null ||
                          tripulacion.medicoSalida === "" ||
                          cambiarMedico)
                      }
                      style={
                        cambiarMedico
                          ? { backgroundColor: "green", color: "white" }
                          : {}
                      }
                      onClick={handleClickOpenMedico}
                    >
                      Cambiar Médico
                    </Button>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Autocomplete
                      id="chofer"
                      fullWidth
                      options={choferesArray}
                      disablePortal
                      defaultValue={
                        choferesArray &&
                        selectedRow &&
                        selectedRow.choferId !== undefined
                          ? choferesArray[
                              choferesArray.findIndex(
                                (item) => item.id === selectedRow.choferId
                              )
                            ]
                          : null
                      }
                      noOptionsText={"No hay opciones"}
                      disabled={
                        tripulacion &&
                        tripulacion.choferIngreso !== null &&
                        tripulacion.choferIngreso !== ""
                      }
                      getOptionLabel={(option) => option.nombre}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Choferes"
                          variant="standard"
                        />
                      )}
                      //onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        if (value) {
                          guardarTripulacion({
                            ...tripulacion,
                            choferId: value.id,
                          });
                        } else {
                          guardarTripulacion({
                            ...tripulacion,
                            choferId: 0,
                          });
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <TextField
                      id="choferIngreso"
                      label="Ingreso"
                      type="datetime-local"
                      variant="standard"
                      disablePortal
                      disabled={
                        tripulacion &&
                        (tripulacion.choferId === "" ||
                          tripulacion.choferId === null ||
                          tripulacion.choferId === 0 ||
                          (tripulacion.choferIngreso !== "" &&
                            tripulacion.choferIngreso !== null))
                          ? true
                          : false
                      }
                      noOptionsText={"No hay opciones"}
                      defaultValue={
                        selectedRow &&
                        selectedRow.choferIngreso !== "" &&
                        selectedRow.choferIngreso !== null
                          ? selectedRow.choferIngreso
                          : null
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          guardarTripulacion({
                            ...tripulacion,
                            choferIngreso: e.target.value,
                          });
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <TextField
                      id="choferSalida"
                      label="Salida"
                      type="datetime-local"
                      variant="standard"
                      error={
                        tripulacion &&
                        tripulacion.choferSalida !== null &&
                        tripulacion.choferSalida !== "" &&
                        tripulacion.choferSalida <= tripulacion.choferIngreso
                      }
                      helperText={
                        tripulacion &&
                        tripulacion.choferSalida !== null &&
                        tripulacion.choferSalida !== "" &&
                        tripulacion.choferSalida <= tripulacion.choferIngreso
                          ? "Error en la fecha"
                          : tripulacion &&
                            tripulacion.servicioId !== null &&
                            tripulacion.servicioId !== 0
                          ? "En servicio"
                          : " "
                      }
                      disabled={
                        tripulacion &&
                        (tripulacion.choferIngreso === null ||
                          tripulacion.choferIngreso === "" ||
                          (tripulacion.choferSalida != null &&
                            tripulacion.choferSalida !== "" &&
                            tripulacion.choferSalida >
                              tripulacion.choferIngreso) ||
                          (tripulacion.servicioId !== null &&
                            tripulacion.servicioId !== 0))
                          ? true
                          : false
                      }
                      defaultValue={
                        selectedRow &&
                        selectedRow.choferSalida !== null &&
                        selectedRow.choferSalida !== ""
                          ? selectedRow.choferSalida
                          : null
                      }
                      //defaultValue="2017-05-24T10:30"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          guardarTripulacion({
                            ...tripulacion,
                            choferSalida: e.target.value,
                          });
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2} />

                  <Grid item xs={12} md={2}>
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      disableElevation
                      disabled={
                        tripulacion &&
                        (tripulacion.choferSalida === null ||
                          tripulacion.choferSalida === "" ||
                          cambiarChofer)
                      }
                      style={
                        cambiarChofer
                          ? { backgroundColor: "green", color: "white" }
                          : {}
                      }
                      onClick={handleClickOpenChofer}
                    >
                      Cambiar Chofer
                    </Button>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Autocomplete
                      id="enfermero"
                      fullWidth
                      options={enfermerosArray}
                      disablePortal
                      defaultValue={
                        enfermerosArray &&
                        selectedRow &&
                        selectedRow.enfermeroId !== undefined
                          ? enfermerosArray[
                              enfermerosArray.findIndex(
                                (item) => item.id === selectedRow.enfermeroId
                              )
                            ]
                          : null
                      }
                      noOptionsText={"No hay opciones"}
                      disabled={
                        tripulacion &&
                        tripulacion.enfermeroIngreso !== null &&
                        tripulacion.enfermeroIngreso !== ""
                      }
                      getOptionLabel={(option) => option.nombre}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Enfermeros"
                          variant="standard"
                        />
                      )}
                      //onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        if (value) {
                          guardarTripulacion({
                            ...tripulacion,
                            enfermeroId: value.id,
                          });
                        } else {
                          guardarTripulacion({
                            ...tripulacion,
                            enfermeroId: 0,
                          });
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <TextField
                      id="enfermeroIngreso"
                      label="Ingreso"
                      type="datetime-local"
                      variant="standard"
                      disabled={
                        tripulacion &&
                        (tripulacion.enfermeroId === "" ||
                          tripulacion.enfermeroId === null ||
                          tripulacion.enfermeroId === 0 ||
                          (tripulacion.enfermeroIngreso !== "" &&
                            tripulacion.enfermeroIngreso !== null))
                          ? true
                          : false
                      }
                      defaultValue={
                        selectedRow &&
                        selectedRow.enfermeroIngreso !== "" &&
                        selectedRow.enfermeroIngreso !== null
                          ? selectedRow.enfermeroIngreso
                          : null
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          guardarTripulacion({
                            ...tripulacion,
                            enfermeroIngreso: e.target.value,
                          });
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <TextField
                      id="enfermeroSalida"
                      label="Salida"
                      type="datetime-local"
                      variant="standard"
                      error={
                        tripulacion &&
                        tripulacion.enfermeroSalida !== null &&
                        tripulacion.enfermeroSalida !== "" &&
                        tripulacion.enfermeroSalida <=
                          tripulacion.enfermeroIngreso
                      }
                      helperText={
                        tripulacion &&
                        tripulacion.enfermeroSalida !== null &&
                        tripulacion.enfermeroSalida !== "" &&
                        tripulacion.enfermeroSalida <=
                          tripulacion.enfermeroIngreso
                          ? "Error en la fecha"
                          : tripulacion &&
                            tripulacion.servicioId !== null &&
                            tripulacion.servicioId !== 0
                          ? "En servicio"
                          : " "
                      }
                      disabled={
                        tripulacion &&
                        (tripulacion.enfermeroIngreso === "" ||
                          tripulacion.enfermeroIngreso === null ||
                          (tripulacion.enfermeroSalida !== "" &&
                            tripulacion.enfermeroSalida != null &&
                            tripulacion.enfermeroSalida >
                              tripulacion.enfermeroIngreso) ||
                          (tripulacion.servicioId !== null &&
                            tripulacion.servicioId !== 0))
                          ? true
                          : false
                      }
                      defaultValue={
                        selectedRow &&
                        selectedRow.enfermeroSalida !== "" &&
                        selectedRow.enfermeroSalida !== null
                          ? selectedRow.enfermeroSalida
                          : null
                      }
                      //defaultValue="2017-05-24T10:30"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          guardarTripulacion({
                            ...tripulacion,
                            enfermeroSalida: e.target.value,
                          });
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2} />

                  <Grid item xs={12} md={2}>
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      disableElevation
                      disabled={
                        tripulacion &&
                        (tripulacion.enfermeroSalida === "" ||
                          tripulacion.enfermeroSalida === null ||
                          cambiarEnfermero)
                      }
                      style={
                        cambiarEnfermero
                          ? { backgroundColor: "green", color: "white" }
                          : {}
                      }
                      onClick={handleClickOpenEnfermero}
                    >
                      Cambiar Enfermero
                    </Button>
                  </Grid>
                  <Grid item xs={6} md={4} style={{ paddingLeft: 0 }}>
                    <FormControlLabel
                      value="yes"
                      control={
                        <Checkbox
                          color="primary"
                          checked={tripulacion ? tripulacion.activo : false}
                          style={{ marginLeft: "1vw" }}
                          onChange={(e) =>
                            guardarTripulacion({
                              ...tripulacion,
                              activo: e.target.checked,
                            })
                          }
                        />
                      }
                      label="Activo"
                      labelPlacement="start"
                    />
                  </Grid>
                  <Grid item xs={6} md={4} style={{ paddingLeft: 0 }}>
                    <FormControlLabel
                      value="yes"
                      control={
                        <Checkbox
                          color="primary"
                          checked={
                            tripulacion ? tripulacion.localizacion : false
                          }
                          style={{ marginLeft: "1vw" }}
                          onChange={(e) =>
                            guardarTripulacion({
                              ...tripulacion,
                              localizacion: e.target.checked,
                            })
                          }
                        />
                      }
                      label="Localización"
                      labelPlacement="start"
                    />
                  </Grid>

                  <Grid item xs={6} md={4} style={{ paddingLeft: 0 }}>
                    <FormControlLabel
                      value="yes"
                      control={
                        <Checkbox
                          color="primary"
                          checked={tripulacion ? tripulacion.cerrar : false}
                          disabled={
                            (tripulacion &&
                              tripulacion.medicoId !== null &&
                              tripulacion.medicoId !== 0 &&
                              (tripulacion.medicoSalida === "" ||
                                tripulacion.medicoSalida === null)) ||
                            (tripulacion &&
                              tripulacion.choferId !== null &&
                              tripulacion.choferId !== 0 &&
                              (tripulacion.choferSalida === "" ||
                                tripulacion.choferSalida === null)) ||
                            (tripulacion &&
                              tripulacion.enfermeroId !== null &&
                              tripulacion.enfermeroId !== 0 &&
                              (tripulacion.enfermeroSalida === "" ||
                                tripulacion.enfermeroSalida === null))
                              ? true
                              : false
                          }
                          style={{ marginLeft: "1vw" }}
                          onChange={(e) =>
                            guardarTripulacion({
                              ...tripulacion,
                              cerrar: e.target.checked,
                            })
                          }
                        />
                      }
                      label="Cerrar"
                      labelPlacement="start"
                    />
                  </Grid>
                </Grid>
              </Dialog>

              <Dialog
                header={renderHeaderModificar()}
                visible={visibleModificaciones}
                closable={false}
                footer={renderFooterModificar()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "90vw", paddingBottom: "0" }}
                position="center"
              >
                <Grid container spacing={1} style={{ padding: "2vw" }}>
                  {tripulacionModificada ? (
                    <Fragment>
                      <Grid item xs={12} md={4}>
                        <Autocomplete
                          id="base"
                          fullWidth
                          options={bases}
                          disablePortal
                          defaultValue={
                            bases &&
                            tripulacionModificada &&
                            tripulacionModificada.baseId !== undefined
                              ? bases[
                                  bases.findIndex(
                                    (item) =>
                                      item.id === tripulacionModificada.baseId
                                  )
                                ]
                              : null
                          }
                          noOptionsText={"No hay opciones"}
                          getOptionLabel={(option) => option.nombre}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Bases"
                              variant="standard"
                            />
                          )}
                          //onKeyDown={handleEnter}
                          onChange={(event, value) => {
                            if (value) {
                              guardarTripulacionModificada({
                                ...tripulacionModificada,
                                baseId: value.id,
                              });
                            }
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={3}>
                        <Autocomplete
                          id="movil"
                          fullWidth
                          options={moviles}
                          disablePortal
                          defaultValue={
                            moviles &&
                            tripulacionModificada.movilId !== undefined
                              ? moviles[
                                  moviles.findIndex(
                                    (item) =>
                                      item.id === tripulacionModificada.movilId
                                  )
                                ]
                              : null
                          }
                          noOptionsText={"No hay opciones"}
                          getOptionLabel={(option) => option.nombre}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Móviles"
                              variant="standard"
                            />
                          )}
                          //onKeyDown={handleEnter}
                          onChange={(event, value) => {
                            if (value) {
                              guardarTripulacionModificada({
                                ...tripulacionModificada,
                                movilId: value.id,
                              });
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Autocomplete
                          id="telefono"
                          fullWidth
                          options={telefonos}
                          disablePortal
                          defaultValue={
                            telefonos &&
                            tripulacionModificada &&
                            tripulacionModificada.telefonoId !== undefined
                              ? telefonos[
                                  telefonos.findIndex(
                                    (item) =>
                                      item.id ===
                                      tripulacionModificada.telefonoId
                                  )
                                ]
                              : null
                          }
                          noOptionsText={"No hay opciones"}
                          getOptionLabel={(option) => option.nombre}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Teléfonos"
                              variant="standard"
                            />
                          )}
                          //onKeyDown={handleEnter}
                          onChange={(event, value) => {
                            if (value) {
                              guardarTripulacionModificada({
                                ...tripulacionModificada,
                                telefonoId: value.id,
                                localizacion: true,
                              });
                            } else {
                              guardarTripulacionModificada({
                                ...tripulacionModificada,
                                telefonoId: 0,
                                localizacion: false,
                              });
                            }
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <Autocomplete
                          id="medico"
                          fullWidth
                          options={medicos}
                          disablePortal
                          defaultValue={
                            medicos &&
                            tripulacionModificada &&
                            tripulacionModificada.medicoId !== undefined
                              ? medicos[
                                  medicos.findIndex(
                                    (item) =>
                                      item.id === tripulacionModificada.medicoId
                                  )
                                ]
                              : null
                          }
                          noOptionsText={"No hay opciones"}
                          getOptionLabel={(option) => option.nombre}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Médicos"
                              variant="standard"
                            />
                          )}
                          //onKeyDown={handleEnter}
                          onChange={(event, value) => {
                            if (value) {
                              guardarTripulacionModificada({
                                ...tripulacionModificada,
                                medicoId: value.id,
                              });
                            } else {
                              guardarTripulacionModificada({
                                ...tripulacionModificada,
                                medicoId: 0,
                              });
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <TextField
                          id="medicoIngreso"
                          label="Ingreso"
                          type="datetime-local"
                          variant="standard"
                          disabled={
                            tripulacionModificada &&
                            (tripulacionModificada.medicoId === "" ||
                              tripulacionModificada.medicoId === null ||
                              tripulacionModificada.medicoId === 0)
                              ? true
                              : false
                          }
                          noOptionsText={"No hay opciones"}
                          defaultValue={
                            tripulacionModificada &&
                            tripulacionModificada.medicoIngreso !== "" &&
                            tripulacionModificada.medicoIngreso !== null
                              ? tripulacionModificada.medicoIngreso
                              : null
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => {
                            guardarTripulacionModificada({
                              ...tripulacionModificada,
                              medicoIngreso: e.target.value,
                            });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <TextField
                          id="medicoSalida"
                          label="Salida"
                          type="datetime-local"
                          variant="standard"
                          error={
                            tripulacionModificada &&
                            tripulacionModificada.medicoSalida !== null &&
                            tripulacionModificada.medicoSalida !== "" &&
                            tripulacionModificada.medicoSalida <=
                              tripulacionModificada.medicoIngreso
                          }
                          helperText={
                            tripulacionModificada &&
                            tripulacionModificada.medicoSalida !== null &&
                            tripulacionModificada.medicoSalida !== "" &&
                            tripulacionModificada.medicoSalida <=
                              tripulacionModificada.medicoIngreso
                              ? "Error en la fecha"
                              : " "
                          }
                          disabled={
                            tripulacionModificada &&
                            (tripulacionModificada.medicoIngreso === null ||
                              tripulacionModificada.medicoIngreso === "")
                              ? true
                              : false
                          }
                          defaultValue={
                            tripulacionModificada &&
                            tripulacionModificada.medicoSalida !== null &&
                            tripulacionModificada.medicoSalida !== ""
                              ? tripulacionModificada.medicoSalida
                              : null
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => {
                            guardarTripulacionModificada({
                              ...tripulacionModificada,
                              medicoSalida: e.target.value,
                            });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2} />

                      <Grid item xs={12} md={4}>
                        <Autocomplete
                          id="chofer"
                          fullWidth
                          options={choferes}
                          disablePortal
                          defaultValue={
                            choferes &&
                            tripulacionModificada &&
                            tripulacionModificada.choferId !== undefined
                              ? choferes[
                                  choferes.findIndex(
                                    (item) =>
                                      item.id === tripulacionModificada.choferId
                                  )
                                ]
                              : null
                          }
                          noOptionsText={"No hay opciones"}
                          getOptionLabel={(option) => option.nombre}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Choferes"
                              variant="standard"
                            />
                          )}
                          //onKeyDown={handleEnter}
                          onChange={(event, value) => {
                            if (value) {
                              guardarTripulacionModificada({
                                ...tripulacionModificada,
                                choferId: value.id,
                              });
                            } else {
                              guardarTripulacionModificada({
                                ...tripulacionModificada,
                                choferId: 0,
                              });
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <TextField
                          id="choferIngreso"
                          label="Ingreso"
                          type="datetime-local"
                          variant="standard"
                          disabled={
                            tripulacionModificada &&
                            (tripulacionModificada.choferId === "" ||
                              tripulacionModificada.choferId === null ||
                              tripulacionModificada.choferId === 0)
                              ? true
                              : false
                          }
                          disablePortal
                          noOptionsText={"No hay opciones"}
                          defaultValue={
                            tripulacionModificada &&
                            tripulacionModificada.choferIngreso !== "" &&
                            tripulacionModificada.choferIngreso !== null
                              ? tripulacionModificada.choferIngreso
                              : null
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => {
                            guardarTripulacionModificada({
                              ...tripulacionModificada,
                              choferIngreso: e.target.value,
                            });
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={3}>
                        <TextField
                          id="choferSalida"
                          label="Salida"
                          type="datetime-local"
                          variant="standard"
                          error={
                            tripulacionModificada &&
                            tripulacionModificada.choferSalida !== null &&
                            tripulacionModificada.choferSalida !== "" &&
                            tripulacionModificada.choferSalida <=
                              tripulacionModificada.choferIngreso
                          }
                          helperText={
                            tripulacionModificada &&
                            tripulacionModificada.choferSalida !== null &&
                            tripulacionModificada.choferSalida !== "" &&
                            tripulacionModificada.choferSalida <=
                              tripulacionModificada.choferIngreso
                              ? "Error en la fecha"
                              : " "
                          }
                          disabled={
                            tripulacionModificada &&
                            (tripulacionModificada.choferIngreso === null ||
                              tripulacionModificada.choferIngreso === "")
                              ? true
                              : false
                          }
                          defaultValue={
                            tripulacionModificada &&
                            tripulacionModificada.choferSalida !== null &&
                            tripulacionModificada.choferSalida !== ""
                              ? tripulacionModificada.choferSalida
                              : null
                          }
                          //defaultValue="2017-05-24T10:30"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => {
                            guardarTripulacionModificada({
                              ...tripulacionModificada,
                              choferSalida: e.target.value,
                            });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2} />

                      <Grid item xs={12} md={4}>
                        <Autocomplete
                          id="enfermero"
                          fullWidth
                          options={enfermeros}
                          disablePortal
                          defaultValue={
                            enfermeros &&
                            tripulacionModificada &&
                            tripulacionModificada.enfermeroId !== undefined
                              ? enfermeros[
                                  enfermeros.findIndex(
                                    (item) =>
                                      item.id ===
                                      tripulacionModificada.enfermeroId
                                  )
                                ]
                              : null
                          }
                          noOptionsText={"No hay opciones"}
                          getOptionLabel={(option) => option.nombre}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Enfermeros"
                              variant="standard"
                            />
                          )}
                          //onKeyDown={handleEnter}
                          onChange={(event, value) => {
                            if (value) {
                              guardarTripulacionModificada({
                                ...tripulacionModificada,
                                enfermeroId: value.id,
                              });
                            } else {
                              guardarTripulacionModificada({
                                ...tripulacionModificada,
                                enfermeroId: 0,
                              });
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <TextField
                          id="enfermeroIngreso"
                          label="Ingreso"
                          type="datetime-local"
                          variant="standard"
                          disabled={
                            tripulacionModificada &&
                            (tripulacionModificada.enfermeroId === "" ||
                              tripulacionModificada.enfermeroId === null ||
                              tripulacionModificada.enfermeroId === 0)
                              ? true
                              : false
                          }
                          defaultValue={
                            tripulacionModificada &&
                            tripulacionModificada.enfermeroIngreso !== "" &&
                            tripulacionModificada.enfermeroIngreso !== null
                              ? tripulacionModificada.enfermeroIngreso
                              : null
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => {
                            guardarTripulacionModificada({
                              ...tripulacionModificada,
                              enfermeroIngreso: e.target.value,
                            });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <TextField
                          id="enfermeroSalida"
                          label="Salida"
                          type="datetime-local"
                          variant="standard"
                          error={
                            tripulacionModificada &&
                            tripulacionModificada.enfermeroSalida !== null &&
                            tripulacionModificada.enfermeroSalida !== "" &&
                            tripulacionModificada.enfermeroSalida <=
                              tripulacionModificada.enfermeroIngreso
                          }
                          helperText={
                            tripulacionModificada &&
                            tripulacionModificada.enfermeroSalida !== null &&
                            tripulacionModificada.enfermeroSalida !== "" &&
                            tripulacionModificada.enfermeroSalida <=
                              tripulacionModificada.enfermeroIngreso
                              ? "Error en la fecha"
                              : " "
                          }
                          disabled={
                            tripulacionModificada &&
                            (tripulacionModificada.enfermeroIngreso === null ||
                              tripulacionModificada.enfermeroIngreso === "")
                              ? true
                              : false
                          }
                          defaultValue={
                            tripulacionModificada &&
                            tripulacionModificada.enfermeroSalida !== "" &&
                            tripulacionModificada.enfermeroSalida !== null
                              ? tripulacionModificada.enfermeroSalida
                              : null
                          }
                          //defaultValue="2017-05-24T10:30"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => {
                            guardarTripulacionModificada({
                              ...tripulacionModificada,
                              enfermeroSalida: e.target.value,
                            });
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={2} />
                    </Fragment>
                  ) : null}
                </Grid>
                <br />
              </Dialog>
            </Fragment>
          ) : (
            <Spinner />
          )}
        </Fragment>
      ) : null}
    </Fragment>
  );
}
