import React, { useEffect, useContext, Fragment, useState } from "react";
//import { ThemeProvider}  from '@mui/material/styles';
//import { makeStyles }  from '@mui/styles';
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import logo1 from "../../assets/images/mediclogoblanco.png";
import logo2 from "../../assets/images/cardiologo.png";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { ConfiguracionContext } from "../../context/ConfiguracionContext";
import MenuPrincipal from "./Menu";
import { Navigate } from "react-router";
//import { createMuiTheme, MuiThemeProvider } from "tss-react/mui";
import { createTheme } from "@mui/material/styles";
import PeopleIcon from "@mui/icons-material/People";
import hcOffcanvasNav from "hc-offcanvas-nav";
import $ from "jquery";
import { Button } from "primereact/button";


const PREFIX = "MyCard";
const classes = {
  root: `${PREFIX}-root`,
  grow: `${PREFIX}-grow`,
  menuButton: `${PREFIX}-menuButton`,
  sectionMobile: `${PREFIX}-sectionMobile`,
  sectionDesktop: `${PREFIX}-sectionDesktop`,
  quick: `${PREFIX}-quick`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    marginBottom: "5vw",
  },
  [`& .${classes.grow}`]: {
    flexGrow: 1,
  },
  [`& .${classes.menuButton}`]: {
    marginLeft: "0.5em",
    marginRight: theme.spacing(2),
  },
  [`& .${classes.sectionMobile}`]: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  [`& .${classes.sectionDesktop}`]: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  [`& .${classes.quick}`]: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
}));

function Barra(props) {
  const authContext = useContext(AuthContext);
  const { state, autenticado, usuarioAutenticado, cerrarSesion } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const { configuracion, obtenerConfiguracion } = configuracionContext;

  const [redirect, setRedirect] = useState(false);
  const [loginMedicos, setLoginMedicos] = useState(false);
  const [color, setColor] = useState(null);
  const [redirectTomaServicio, setRedirectTomaServicio] = useState(false);
  const [redirectDespacho, setRedirectDespacho] = useState(false);

  
  useEffect(() => {
    obtenerConfiguracion();
  }, []);

  
  useEffect(() => {
    usuarioAutenticado();
  }, []);

  

  //const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const cerrar = () => {
    handleMenuClose();
    cerrarSesion();
    setRedirectTomaServicio(false);
    setRedirectDespacho(false);
    setRedirect(true);
    setTimeout(() => {
      window.location.reload("/");
    }, 1500);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {/*
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      */}

      {state && state.autenticado !== true ? (
        <MenuItem component={Link} to="/login" onClick={handleMenuClose}>
          Iniciar Sesión
        </MenuItem>
      ) : (
        <MenuItem onClick={cerrar}>Cerrar Sesión</MenuItem>
      )}
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/*
      <MenuItem>
        <IconButton aria-label="medicos" color="inherit">
          <PeopleIcon />
        </IconButton>
        <p>Médicos</p>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={5} color="secondary">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
      */}

      {state && state.autenticado !== true ? (
        <MenuItem component={Link} to="/login" onClick={handleMenuClose}>
          Iniciar Sesión
        </MenuItem>
      ) : (
        <MenuItem onClick={cerrar}>Cerrar Sesión</MenuItem>
      )}
    </Menu>
  );

  const loginMedico = () => {
    setLoginMedicos(true);
  };

  const prueba = () => {
    var Nav = new hcOffcanvasNav("#main-nav", {
      disableAt: false,
      customToggle: ".toggle",
      levelSpacing: 40,
      navTitle: "Home",
      levelTitles: true,
      levelTitleAsBack: true,
      pushContent: "#container",
      labelClose: false,
      position: "left",
      levelOpen: "overlap",
    });
    Nav.open(2, 1);
  };

  //console.log(state)

  return (
    <Root className={classes.root}>
      <AppBar
        position="fixed"
        style={{
          backgroundColor:
            configuracion && configuracion.destinatario === 1
              ? "#D8261C"
              : configuracion && configuracion.destinatario === 2
              ? "#E36D00"
              : "#0000",
        }}
      >
        <Toolbar>
          {state && state.autenticado === true ? (
            <Fragment>
              <MenuPrincipal />
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="open drawer"
              >
                <MenuIcon />
              </IconButton>
            </Fragment>
          ) : null}

          {configuracion && configuracion.destinatario === 1 ? (
            <a href="/">
              <img
                src={logo1}
                alt="Logo"
                height="50"
                style={{ marginLeft: "2em", marginTop: "-0.3em" }}
              />
            </a>
          ) : null}
          {configuracion && configuracion.destinatario === 2 ? (
            <a href="/">
              <img
                src={logo2}
                alt="Logo"
                height="50"
                style={{ marginLeft: "2em", marginTop: "0.2em" }}
              />
            </a>
          ) : null}

          {state && state.autenticado === true ? (
            <Fragment>
              <Button
                style={{
                  marginTop: 0,
                  marginBottom: 0,
                  paddingTop: 0,
                  paddingBottom: 0,
                  marginLeft: "2vw",
                }}
                icon="pi pi-phone"
                className={
                  "p-button-danger p-button-rounded" + " " + classes.quick
                }
                onClick={() => {
                  setRedirectTomaServicio(true);
                  setRedirectDespacho(false);
                }}
                tooltip="Toma de Servicios"
                tooltipOptions={{
                  position: "bottom",
                  mouseTrack: true,
                  mouseTrackTop: 15,
                  className: classes.tooltip,
                }}
              />

              <Button
                style={{
                  marginTop: 0,
                  marginBottom: 0,
                  paddingTop: 0,
                  paddingBottom: 0,
                  marginLeft: "2vw",
                }}
                icon="pi pi-arrow-right"
                className={
                  "p-button-danger p-button-rounded" + " " + classes.quick
                }
                onClick={() => {
                  setRedirectTomaServicio(false);
                  setRedirectDespacho(true);
                }}
                tooltip="Despachos"
                tooltipOptions={{
                  position: "bottom",
                  mouseTrack: true,
                  mouseTrackTop: 15,
                  className: classes.tooltip,
                }}
              />
            </Fragment>
          ) : null}

          {/*
          <a href="#" onClick={prueba}>
            Menú
          </a>
          */}
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <div style={{ marginTop: ".7vw" }}>
              {state.autenticado ? state.nombre : null}
            </div>
            {/*
            <IconButton aria-label="medicos" color="inherit" onClick={loginMedico}>
              <PeopleIcon />
            </IconButton>
            <IconButton aria-label="show 4 new mails" color="inherit">
              <Badge badgeContent={4} color="primary">
                <MailIcon />
              </Badge>
            </IconButton>
            <IconButton aria-label="show 17 new notifications" color="inherit">
              <Badge badgeContent={17} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            */}
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <div style={{ marginTop: "2vw" }}>
              {state.autenticado ? state.nombre : null}
            </div>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      {redirect ? <Navigate to="/" /> : null}
      {redirectTomaServicio ? <Navigate to="/tomaservicio" /> : null}
      {redirectDespacho ? <Navigate to="/despachos" /> : null}
      {/*{loginMedicos ? <Navigate to="/loginmedicos" /> : null}*/}
    </Root>
  );
}

export default Barra;
